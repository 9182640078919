
    import './styles.scoped.css';
    export default {
  "trigger-button-styles": "awsui_trigger-button-styles_lpshu_1e3ch_141",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_1e3ch_164",
  "trigger": "awsui_trigger_lpshu_1e3ch_141",
  "selected": "awsui_selected_lpshu_1e3ch_208",
  "badge": "awsui_badge_lpshu_1e3ch_228",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_1e3ch_232",
  "dot": "awsui_dot_lpshu_1e3ch_240"
};
  