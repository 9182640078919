import React, { useContext } from 'react'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadButton } from '../common/RadButton'

export function Chipi () {
  const { user } = useContext(AppContext)
  const { data: types } = useGet('/api/type?limit=1000')
  const { data: projects } = useGet('/api/project?limit=1000')
  const { data: resources } = useGet('/api/resource?limit=1000')
  const { data: targetAreas } = useGet('/api/target-area?limit=100000')

  const projectDataTypes = [
    {
      name: 'Connecticut Health Enhancement Communities - Developing a Data Framework and Glide Path for Community Collaboratives to Improve Population Health',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'King County Data Across Sectors for Housing and Health',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      name: 'Monterey County Active Referral Network',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'Fort Bend County Collaborative Information System (FBCCIS) - OnLine System for Coordinated Access & Referral (OSCAR)',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      name: 'Health Equity Data Portal for Immigrant and Mixed-Status Communities in Iowa',
      data_types: '{{Demographics},{Place},{Service}}'
    },
    {
      name: 'White Earth Nation WECARE Implementation Project',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      name: 'Building a Shared Information System Based on Neighborhood Tabulation Areas to Enhance the Community Health Improvement Capacity in New York City',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      name: 'A Just and Equitable Economic Recovery for Washingtonians',
      data_types: '{{Demographics},{Service}}'
    },
    {
      name: 'Allegheny County Data Sharing Alliance for Health',
      data_types: '{{Demographics},{Person_information},{Place}}'
    },
    {
      name: 'Childhood Lead Hazard Data Sharing',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      name: 'Food For Health: Coordinating Care Across Sectors to Improve Health Among Vulnerable Populations',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      name: 'Statewide HMIS Data Warehouse for Health Coordination',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'Altair Accountable Care for People with Disabilities',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'Leveraging Microdata from South Carolina Integrated Data System to Inform Rural Community Health and Educational Initiatives',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'Sustainable Community Resource Inventory Partnership (SCRIP)',
      data_types: '{{Service}}'
    },
    {
      name: 'Integrated Data for Health and Justice in Rhode Island',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'A Multisector Data Sharing Plan for EcoDistricts: Cleveland as Prototype',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      name: 'Maine Data Across Sectors for Health (DASH) Project',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      name: 'Hartford Data Collaborative Behavioral Health Indicators for City Youth',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      name: 'Burlington City Food Access Network (BC-FAN) - ABCD-Cohort',
      data_types: '{{Service}}'
    },
    {
      name: "Baltimore Falls Reduction Initiative Engaging Neighborhoods and Data (B'FRIEND)",
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      name: 'Invert the Burden: How to Build a More Equitable Service Access System to Address Persistent Inequities in Metro Atlanta',
      data_types: '{{Demographics},{Person_information},{Place}}'
    },
    {
      name: 'San Juan County Aging DASH Grant',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      name: 'Developing a Roadmap for Democratizing Data for Housing and Homelessness Leveraging COVID-19 Funding',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      name: 'Support for Creation of Statewide Food, Hunger and Farm Data Infrastructure to Address Hunger and Food and Water Insecurity Worsened by COVID-19',
      data_types: '{{Service}}'
    },
    {
      name: 'Using a Connected Information System to Enable Spontaneous, Shared Community Treatment of Adults with Severe Mental Illness',
      data_types: '{{Demographics},{Person_information},{Service}}'
    }
  ]

  const resourceDataTypes = [
    {
      title: 'Privacy and data sharing',
      data_types: '{{Service}}'
    },
    {
      title: 'Sharing sensitive data within government',
      data_types: '{{Person_information}}'
    },
    {
      title: 'A solutions-based approach to building data-sharing partnerships',
      data_types: '{}'
    },
    {
      title: 'Why am I always being researched? A guidebook for community organizations, researchers, and funders to help us get from insufficient understanding to more authentic truth',
      data_types: '{}'
    },
    {
      title: 'Building a Plan on a Page Tool',
      data_types: '{}'
    },
    {
      title: 'Thriving Together: A Springboard for Equitable Recovery and Resilience in Communities Across America',
      data_types: '{}'
    },
    {
      title: 'Community Approaches to System Change',
      data_types: '{}'
    },
    {
      title: 'Snapshot: CIPSEA',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'How HIPAA supports data sharing',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Health information privacy law and policy',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data sharing and the law: deep dive on consent',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data Sharing to Build Effective and Efficient Benefits Systems',
      data_types: '{}'
    },
    {
      title: 'Federal public health laws supporting data use and sharing',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Public health collection, use, sharing, and protection of information',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Legal issues related to sharing of clinical health data with public health agencies',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Immunization data sharing, HIPAA, and MIIC',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Patient consent for electronic health information exchange and interoperability',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Medical data sharing:establishing trust in health information exchange',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Behavioral health data sharing toolkit',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Court dependents sharing confidential mental health information',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Substance use disorder confidentiality regulations and care integration in medicaid',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Sharing research data and intellectual property law: a primer',
      data_types: '{}'
    },
    {
      title: '[how to] Submit a data sharing application',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Guidance on private sector information sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'State data sharing initiative tools: confidentiality laws & regulations database',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Bureau of justice statistics data protection guidelines',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Information sharing in criminal justice–mental health collaborations',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Addressing the opioid epidemic: information sharing toolkit',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'A note on HIPAA and 42 CFR part 2',
      data_types: '{{Service}}'
    },
    {
      title: 'Financial aid data sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'A legal approach to sharing health & education data',
      data_types: '{{Service}}'
    },
    {
      title: 'State student privacy laws',
      data_types: '{{Service}}'
    },
    {
      title: 'Data sharing road map:improving student outcomes through partnerships between public housing agencies and school districts',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Comparison of FERPA and HIPAA privacy rule for accessing student health data',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Navigate the privacy evaluation questions',
      data_types: '{{Person_information}}'
    },
    {
      title: 'School resource officers navigating information sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Roadmap for foster care and K–12 data linkages',
      data_types: '{{Service}}'
    },
    {
      title: 'A framework for state-level information sharing and analysis organizations',
      data_types: '{}'
    },
    {
      title: 'Legal issues for IDS use: finding a way forward',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Student data privacy: best practices',
      data_types: '{{Service}}'
    },
    {
      title: 'Dear colleague letter: foster youth information-sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data sharing for policy analysis & program evaluation: legal guide book',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Information sharing: case examples',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Crossover youth consent to share confidential information',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'The leaders library card challenge success strategy',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'WINTAC toolkit –data sharing agreements',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Information sharing in medical-legal partnerships: foundational concepts and resources',
      data_types: '{{Service}}'
    },
    {
      title: 'Foodborne disease outbreak response: assessing the legal and institutional framework for interagency information sharing',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Food banks as partners in health promotion: how HIPAA and concerns about protecting patient information affect your partnership',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Privacy protections in selected federal benefits programs',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      title: 'The privacy act of 1974',
      data_types: '{}'
    },
    {
      title: 'Privacy of student records collected and maintained by the California department of education',
      data_types: '{{Service}}'
    },
    {
      title: 'Overcoming data-sharing challenges in the opioid epidemic:integrating substance use disorder treatment in primary care',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Sharing information across physical and behavioral health: debunking myths, developing strategies',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'The HIPAA privacy rule: how may covered entities use and disclose health information',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Aligning education and housing: data sharing agreement template for intermediary organizations',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Aligning education and housing: data sharing agreement template',
      data_types: '{{Service}}'
    },
    {
      title: 'Using data to identify housing needs and target resources',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      title: 'Housing and healthcare: partnerships for statewide data sharing',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Lead safe housing rule amendment supplemental materials',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Interagency data sharing agreement Washington State HMIS',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Legal barriers to the growth of health information exchange—boulders or pebbles?',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Achieving the right balance:privacy and security policies to support electronic health information exchange',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Health information exchange and your privacy',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'The current state of sharing behavioral health information in health information exchanges',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: '42 CFR part 2 substance use disorder confidentiality regulations – implications for care integration in medicaid',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Consumer consent options for electronic health information exchange: policy considerations and analysis',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data sharing between law enforcement and schools',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Cross-sector data exchange: data use and legal privacy',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'North Carolina juvenile justice-behavioral health information sharing guide',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Authorities and limitations in sharing information between public health agencies and law enforcement',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Connecting Data Across Sectors to Address Cardiovascular Disease',
      data_types: '{}'
    },
    {
      title: 'Guidance to managed care organizations, health homes, care management agencies, and providers: sharing protected health information for outreach to support enrollment of individuals in health homes',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Human subjects research',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Data-sharing tool kit for communities: how to leverage community relationships while protecting student privacy',
      data_types: '{{Service}}'
    },
    {
      title: 'Accountable communities for health: data-sharing toolkit',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Cybersecurity information sharing act – frequently asked questions',
      data_types: '{}'
    },
    {
      title: 'Sharing data: legal and policy considerations',
      data_types: '{}'
    },
    {
      title: 'Data & reporting: protecting student privacy',
      data_types: '{{Service}}'
    },
    {
      title: 'Data sharing for program evaluation',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'State data use agreement example (Georgia)',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Sample data sharing agreement',
      data_types: '{{Service}}'
    },
    {
      title: 'Maximizing linkages: a policymaker’s guide to data sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Methods and timeline for reporting HIV data: overview of legal requirements for providers, labs, and local health officers',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Pharmacy legal toolkit',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Sustained and coordinated efforts could facilitate data sharing while protecting privacy',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Privacy, HIPAA, and information sharing fact sheet',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Guidance for donor and recipient information sharing',
      data_types: '{{Service}}'
    },
    {
      title: 'ORSA information sharing best practices',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Cybersecurity and information sharing: legal challenges and solutions',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Vocational rehabilitation: confidentiality',
      data_types: '{{Person_information}}'
    },
    {
      title: 'DHS data sharing framework for human services integration',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Improving the exchange and coordination of care for medicaid eligible individuals accessing specialty behavioral health services',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Behavioral health information sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data drives school-community collaboration',
      data_types: '{{Service}}'
    },
    {
      title: 'Data sharing agreement content',
      data_types: '{}'
    },
    {
      title: 'Health information privacy in selected state programs',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Policies and procedures for access to and disclosure of confidential data from the California cancer registry',
      data_types: '{{Place}}'
    },
    {
      title: 'Cross-border data sharing under the cloud act',
      data_types: '{}'
    },
    {
      title: 'Influenza vaccine consent and screening form for children',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      title: 'Exemption 7 of FIOA',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Privacy and synthetic datasets',
      data_types: '{}'
    },
    {
      title: 'Data privacy, data use, and data use agreements',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Privacy choices for your personal financial information',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Data sharing: creating agreements in support of community-academic partnerships',
      data_types: '{}'
    },
    {
      title: 'Data sharing in public benefit programs: an action agenda for removing barriers',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Instructions for completing the data use agreement (DUA) form CMS-R-0235',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Lifeline eligibility verification system - Illinois, DSA',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Sample data use agreement, KU office of research',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Data and information sharing (children)',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Guidelines for developing data sharing agreements to use state administrative data for early care and education research',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'NIH genomic data sharing policy & grantee requirements',
      data_types: '{{Person_information}}'
    },
    {
      title: '2018 state standard of excellence - data policies / agreements',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Access to toxic substances control act confidential business information: a guide for access to TSCA CBI for state local and tribal governments',
      data_types: '{{Person_information}}'
    },
    {
      title: 'A guide to building integrated data systems compatible with federal privacy laws',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'The role of criminal justice data and behavioral health data in whole person care',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Protecting student privacy - data sharing',
      data_types: '{{Service}}'
    },
    {
      title: 'Data governance and student privacy',
      data_types: '{{Service}}'
    },
    {
      title: 'Advancing educational research and student privacy in the “big data” era',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Protecting data, protecting residents',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Local workforce delivery system – memorandum of understanding',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Overview of commissioning and information sharing agreements with state and local government officials - FDA information paper',
      data_types: '{{Person_information},{Place}}'
    },
    {
      title: 'Healthcare data as a public good: privacy and security (chapter 5 of the document "clinical data as the basic staple of health learning: creating and protecting a public good: workshop summary.")',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Overview of the privacy act of 1974',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Conformity requirements for state UC laws',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Principles and protocols for the release of health care data',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Privacy and civil liberties final guidelines: cybersecurity information sharing act of 2015',
      data_types: '{}'
    },
    {
      title: 'Legal issues concerning identifiable health data sharing between state/local public health authorities and tribal epidemiology centers in selected U.S. jurisdictions',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'HIV data privacy and confidentiality: legal & ethical considerations for health department data sharing',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Information sharing: professional and legal responsibilities of law enforcement and prosecutors',
      data_types: '{{Demographics},{Place}}'
    },
    {
      title: 'Information sharing between criminal justice and healthcare communities to enhance health and public safety',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Joint guidance on data matching to facilitate WIOA performance reporting and evaluation',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Confidentiality agreements in the administrative state',
      data_types: '{{Person_information}}'
    },
    {
      title: 'HIPAA for professionals - research',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'HIE management and operational considerations',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'FAQ on NC health information exchange',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Louisiana’s data governance & student privacy guidebook',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Department of public health confidentiality procedures',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'Privacy impact assessment for the law enforcement information sharing service (LEIS service)',
      data_types: '{}'
    },
    {
      title: 'Substitute consent to data sharing: a way forward for international dementia research?',
      data_types: '{}'
    },
    {
      title: 'Legal guide to administrative data sharing for economic and workforce development',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Data sharing regulations/policy/guidance chart for NIH awards',
      data_types: '{}'
    },
    {
      title: 'Gramm-leach-bliley act',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Confidentiality and information sharing (chapter 14 of the manual titled, abuse, neglect, dependency, and termination of parental rights)',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Sharing clinical research data: workshop summary (2013) : chapter 3',
      data_types: '{{Person_information},{Place},{Service}}'
    },
    {
      title: 'SIREN Evidence & Resource Library',
      data_types: '{}'
    },
    {
      title: 'Housing and Homelessness: Unlocking the Value of Data Sharing',
      data_types: '{{Demographics},{Place},{Service}}'
    },
    {
      title: 'Using electronic health data for community health –part 2',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Federal highway administration information-sharing guidebook for transportation management centers, emergency operations centers, and fusion centers',
      data_types: '{{Place}}'
    },
    {
      title: 'Introduction to Data Sharing and Integration',
      data_types: '{}'
    },
    {
      title: 'Transactional Records Access Clearinghouse',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Unlocking the Value of Data Sharing: A Look Across Five Sectors',
      data_types: '{}'
    },
    {
      title: 'DASH Accomplishments Report',
      data_types: '{{Person_information},{Place},{Time}}'
    },
    {
      title: 'Early Learnings from an Emerging Field (DASH Environmental Scan)',
      data_types: '{}'
    },
    {
      title: 'Health Care: Unlocking the Value of Data Sharing Roadmap to engaging state leadership to support community-based multi-sector data sharing',
      data_types: '{{Service}}'
    },
    {
      title: 'IDS Governance: Setting Up for Ethical and Effective Use',
      data_types: '{{Service}}'
    },
    {
      title: 'Partnering with Managed Care Organizations to Share Data for Community Health Improvement',
      data_types: '{{Person_information}}'
    },
    {
      title: 'A Replicable, Solution-Focused Approach to Cross-Sector Data Sharing for Evaluation of Community Violence Prevention Programming',
      data_types: '{}'
    },
    {
      title: 'Tackling Data Dilemmas in Social Care Coordination: Pursuing Open and Equitable Infrastructure Across a Fragmented Health and Social Service Landscape',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      title: 'The State of the Field of Multisector Data Sharing for Community Health (National Inventory 1.0 Report)',
      data_types: '{}'
    },
    {
      title: 'Engagement, Governance, Access, and Protection (EGAP): A Data Governance Framework for Health Data Collected from Black Communities.',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      title: 'A Toolkit for Centering Racial Equity Throughout Data Integration',
      data_types: '{{Demographics},{Person_information},{Place}}'
    },
    {
      title: 'Bringing Community Voice into Cross-Sector Data Sharing: Addressing the Health Care Needs of People Experiencing Homelessness in Arizona',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Bringing Community Voice into Cross-Sector Data Sharing: Reducing Poverty in Washington State',
      data_types: '{}'
    },
    {
      title: 'Bringing Community Voice into Cross-Sector Data Sharing: Supporting Aging Populations in Rural Utah',
      data_types: '{}'
    },
    {
      title: 'DASH Insights Report',
      data_types: '{}'
    },
    {
      title: 'DataWalks An Innovative Way to Share Data with Communities',
      data_types: '{{Demographics},{Person_information},{Place},{Service}}'
    },
    {
      title: 'Bringing Community Voice into Cross-Sector Data Sharing: Addressing the Health Needs of Children and Youth in Rural South Carolina',
      data_types: '{{Person_information}}'
    },
    {
      title: 'A Beacon of Community Hope: Light on the Hill at Mount Wesley',
      data_types: '{{Service}}'
    },
    {
      title: 'Modernizing Consent to Advance Health and Equity',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      title: 'The Water of Systems Change',
      data_types: '{}'
    },
    {
      title: 'Power Mapping Exercise',
      data_types: '{}'
    },
    {
      title: 'Deliver successful technology projects - State Software Budgeting Handbook',
      data_types: '{}'
    },
    {
      title: 'Advancing Health, Equity, and Well-Being through Community-State Data-Sharing Partnerships: Thought Leader Insights (Opportunity Analysis)',
      data_types: '{}'
    },
    {
      title: 'A Compilation of What We Have Learned From the First Wave of COVID-19',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Powering Change: Building Healthy, Equitable Communities Together A Curriculum for Multisector Collaboratives',
      data_types: '{}'
    },
    {
      title: 'Trenton Health Team: A Holistic Approach to Social Services',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Community Informed Programming in a Time of Change',
      data_types: '{}'
    },
    {
      title: 'Rising Equitable Community Data Ecosystems (RECoDE)',
      data_types: '{}'
    },
    {
      title: 'Field Dispatches: LA County Steps Up Role as Social “Safety Net”',
      data_types: '{{Service},{Time}}'
    },
    {
      title: 'A Legal Approach to Sharing Health & Education Data',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      title: 'Deliver successful technology projects-Federal Field Guide',
      data_types: '{}'
    },
    {
      title: 'No Wrong Door: Improving Care Coordination on the White Earth Reservation',
      data_types: '{{Service}}'
    },
    {
      title: 'Baltimore Falls Reduction Initiative Engaging Neighborhoods and Data (B’FRIEND)',
      data_types: '{{Place},{Service}}'
    },
    {
      title: 'Integrated Care for People with Disabilities: Lessons from an Accountable Care Organization',
      data_types: '{{Service}}'
    },
    {
      title: 'Community Approaches to System Change',
      data_types: '{}'
    },
    {
      title: 'Coordinated Whole-Person Care that Addresses Social Determinants of Health: DASH Communities Generate Lessons Learned about the Required Data Infrastructure and Systems',
      data_types: '{{Person_information},{Service}}'
    },
    {
      title: 'Asset-Based Community Development Institute Tool Kit',
      data_types: '{{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Safeguarding our Global Commons: A Systems Change Lab to Monitor, Learn from, and Advance Transformational Change',
      data_types: '{{Place},{Service},{Time}}'
    },
    {
      title: 'Creating a More Complete Picture of Health in NYC Neighborhoods',
      data_types: '{{Place},{Service}}'
    },
    {
      title: 'Sharing Data to Help Food Bank Clients Manage Their Chronic Conditions',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      title: 'Maine Data Across Sectors for Health',
      data_types: '{{Demographics},{Person_information},{Service}}'
    },
    {
      title: 'The Practical Playbook II: Building Multisector Partnerships That Work',
      data_types: '{}'
    },
    {
      title: 'Creating a Better System of Care for Patients with Complex Needs',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Linking Housing and Health Data to Improve Residents’ Well-being',
      data_types: '{{Demographics},{Person_information},{Place}}'
    },
    {
      title: 'Data Capatlism + Algorithmic Racism',
      data_types: '{}'
    },
    {
      title: 'Fundamentals of ACE Screening and Response in Pediatrics',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Participatory Budgeting',
      data_types: '{}'
    },
    {
      title: 'Data Maturity Framework for Not-for-Profit Organizations',
      data_types: '{}'
    },
    {
      title: 'Designing Trustworthy Data Institutions',
      data_types: '{}'
    },
    {
      title: 'Leveraging Community Information Exchanges for Equitable and Inclusive Data: The CIE Data Equity Framework',
      data_types: '{{Service}}'
    },
    {
      title: 'Advancing Health Equity: A Guide to Language, Narrative and Concepts',
      data_types: '{}'
    },
    {
      title: 'Community Approaches to System Change',
      data_types: '{}'
    },
    {
      title: 'Interconnected: Data, Knowledge, and Action for Community Health',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Data Capitalism + Algorithmic Racism',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'A Community-Centered Approach to Data Sharing and Policy Change: Lessons for Advancing Health Equity',
      data_types: '{{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Health Care Data 101',
      data_types: '{}'
    },
    {
      title: 'Aligning Systems with Communities to Advance Equity through Shared Measurement',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Transformational Change Methodology: Assessing the transformational impacts of policies and actions',
      data_types: '{{Service}}'
    },
    {
      title: 'How Can We Share Power with Communities?',
      data_types: '{{Service}}'
    },
    {
      title: 'Power Ladders',
      data_types: '{{Demographics}}'
    },
    {
      title: 'Toolkit for Developing a Proposition Narrative',
      data_types: '{}'
    },
    {
      title: 'Grantmaking with a Racial Justice Lens',
      data_types: '{}'
    },
    {
      title: 'City Health Dashboard',
      data_types: '{{Demographics},{Person_information},{Place},{Service},{Time}}'
    },
    {
      title: 'Our Data Bodies: Reclaiming Our Data',
      data_types: '{{Demographics},{Place}}'
    },
    {
      title: 'Transactional Records Access Clearinghouse (TRAC)',
      data_types: '{{Demographics},{Place},{Service},{Time}}'
    },
    {
      title: 'Principles for Advancing Equitable Data Practice',
      data_types: '{{Demographics},{Person_information}}'
    },
    {
      title: 'Data Resource Center for Child and Adolescent Health (DRC)',
      data_types: '{{Demographics},{Place},{Service},{Time}}'
    },
    {
      title: 'Enforcement, integration, and the future of immigration federalism (by cristina rodriguez, yale law school)',
      data_types: '{{Person_information}}'
    },
    {
      title: 'Building Community Data',
      data_types: '{{Demographics}}'
    },
    {
      title: 'IDS Governance: Setting Up for Ethical and Effective Use',
      data_types: '{}'
    },
    {
      title: 'Guidelines for the filing of confidential information in civil cases',
      data_types: '{{Demographics}}'
    },
    {
      title: 'Recommended informed consent language for data sharing',
      data_types: '{}'
    },
    {
      title: 'Finding a Way Forward: How to Create a Strong Legal Framework for Data Integration',
      data_types: '{}'
    }
  ]

  async function importProjectDataTypes () {
    for (const projectDataType of projectDataTypes) {
      const projectId = projects.find(x => x.name === projectDataType.name).id
      if (projectId != null) {
        const token = await user.getIdToken()
        const response = await fetch('api/project/' + projectId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        if (response.status === 200) {
          const project = await response.json()
          let dirty = false
          for (const dataType of projectDataType.data_types.split(',')) {
            const type = dataType.replaceAll('{', '').replaceAll('}', '').replaceAll('_', ' ')
            if (project.dataTypes.find(x => x.type?.name === type) == null) {
              project.dataTypes.push({ dataTypeId: types.find(x => x.entity === 'data_type' && x.name === type).id })
              dirty = true
            }
          }
          if (dirty) {
            await fetch('api/project/' + project.id, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(project)
            })
          }
        }
      }
    }
  }

  async function importResourceDataTypes () {
    for (const resourceDataType of resourceDataTypes) {
      const resourceId = resources.find(x => x.title === resourceDataType.title)?.id
      if (resourceId != null) {
        const token = await user.getIdToken()
        const response = await fetch('api/resource/' + resourceId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        if (response.status === 200) {
          const resource = await response.json()
          let dirty = false
          for (const dataType of resourceDataType.data_types.split(',')) {
            const type = dataType.replaceAll('{', '').replaceAll('}', '').replaceAll('_', ' ')
            console.log(`"${type}"`)
            if (type.trim() !== '' && resource.dataTypes.find(x => x.type?.name === type) == null) {
              resource.dataTypes.push({ dataTypeId: types.find(x => x.entity === 'data_type' && x.name === type).id })
              dirty = true
            }
          }
          if (dirty) {
            await fetch('api/resource/' + resource.id, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(resource)
            })
          }
        }
      }
    }
  }

  async function updateTargetAreas () {
    const data = require('./cities.json')
    const states = require('./states.json')
    const token = await user.getIdToken()
    for (const targetArea of targetAreas) {
      let row
      switch (targetArea.type) {
        case 'zipcode':
          row = data.find(x => x.zip_code.toString().padStart(5, '0') === targetArea.name)
          break
        case 'city':
          row = data.find(x => x.city + ' ' + x.state === targetArea.name)
          break
        case 'county':
          row = data.find(x => x.county + ' County ' + x.state === targetArea.name)
          break
        case 'state':
          row = null
          break
      }
      if (row != null && targetArea.type !== 'state' && targetArea.state == null) {
        targetArea.city = row.city + ' ' + row.state
        targetArea.county = row.county + ' County ' + row.state
        targetArea.state = states[row.state]
        await fetch('api/target-area/' + targetArea.id, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(targetArea)
        })
      }
    }
    console.log('done')
  }

  return (
    <RadAppLayout
      contentHeader={
        <RadHeader
          variant='h1'
        >
          Chipi
        </RadHeader>
      }
      content={
        <RadContainer>
          <RadSpaceBetween direction='vertical' size='l'>
            <RadButton onClick={importProjectDataTypes}>ETL Project Data Types</RadButton>
            <RadButton onClick={importResourceDataTypes}>ETL Resource Data Types</RadButton>
            <RadButton onClick={updateTargetAreas}>Update Target Areas</RadButton>
          </RadSpaceBetween>
        </RadContainer>
      }
    />
  )
}
