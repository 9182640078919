import React from 'react'
import { usePut } from '../hooks/usePut'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'

export function ReportBrokenLinkButton ({ resource }) {
  const report = usePut(`/api/resource/${resource?.id}/report-broken-link`, { }, null, true, false)

  if (resource.brokenLinkReport != null) {
    return (
      <RadBox display='inline' padding={{ left: 'xs' }}>| Reported</RadBox>
    )
  }

  return (
    <RadBox display='inline' padding={{ left: 'xs' }}>
      |
      <RadBox display='inline' padding={{ left: 'xs' }}>
        <RadButton
          onClick={report}
          variant='inline-link'
        >
          Report Link as Broken
        </RadButton>
      </RadBox>
    </RadBox>
  )
}
