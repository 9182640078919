import React, { useState } from 'react'
import { usePost } from './hooks/usePost'
import { RadBox } from './common/RadBox'
import { RadButton } from './common/RadButton'
import { RadCheckbox } from './common/RadCheckbox'
import { RadDivider } from './common/RadDivider'
import { RadFormField } from './common/RadFormField'
import { RadModal } from './common/RadModal'
import { RadSpaceBetween } from './common/RadSpaceBetween'
import { RadTextarea } from './common/RadTextarea'
import { isNullOrWhitespace } from './common/utilities'
import './AskTheLibrarian.scss'

export function AskTheLibrarianButton () {
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='ask-the-librarian'>
      <RadButton
        variant='primary'
        onClick={() => setShowModal(!showModal)}
      >
        Ask the Librarian / Share feedback
      </RadButton>
      {showModal && <AskTheLibrarianModal dismiss={() => setShowModal(false)} />}
    </div>
  )
}

export function AskTheLibrarianModal ({ dismiss }) {
  const [formValues, setFormValues] = useState({})

  if (formValues != null) {
    return (
      <RadModal
        visible
        onDismiss={dismiss}
        closeAriaLabel='Close modal'
      >
        <div className='header'>Ask the Librarian / Share feedback</div>
        {formValues.action == null &&
          <div className='subheader'>
            We'd love to hear from you! Please
            complete this form to share ideas,
            questions and or suggestions for
            improvement.
          </div>}
        {formValues.action == null &&
          <div className='actions'>
            <RadButton onClick={() => setFormValues({ action: 'question' })} variant='primary'>Ask Question</RadButton>
            <RadButton
              onClick={() => {
                dismiss()
              }}
              variant='primary'
              iconAlign='right'
              iconName='external'
              target='_blank'
              href='https://cdmtr.mphi.org/surveys/?s=XRY949CMJ4CMT4MT'
            >
              Share Feedback
            </RadButton>
            <RadButton
              onClick={() => {
                dismiss()
              }}
              variant='primary'
              iconAlign='right'
              iconName='external'
              target='_blank'
              href='https://cdmtr.mphi.org/surveys/?s=XRY949CMJ4CMT4MT'
            >
              Request Correction
            </RadButton>
            <RadButton
              onClick={() => {
                dismiss()
              }}
              variant='primary'
              iconAlign='right'
              iconName='external'
              target='_blank'
              href='https://cdmtr.mphi.org/surveys/?s=XRY949CMJ4CMT4MT'
            >
              Suggest A Resource
            </RadButton>
          </div>}
        {formValues.action === 'question' && <AskQuestion formValues={formValues} setFormValues={setFormValues} dismiss={dismiss} />}
      </RadModal>
    )
  }
}

function AskQuestion ({ formValues, setFormValues, dismiss }) {
  const submitQuestion = usePost('/api/notification/ask-the-librarian', formValues, (resp) => dismiss())

  return (
    <RadSpaceBetween size='l'>
      <RadFormField label='Your Question' field='question' required>
        <RadTextarea
          rows={2}
          placeholder='Enter your question'
          value={formValues.question}
          onChange={({ detail }) => setFormValues({ ...formValues, question: detail.value })}
        />
      </RadFormField>
      <RadFormField label='More Detail / Explanation' field='detail'>
        <RadTextarea
          rows={2}
          placeholder='Enter more detail or explanation'
          value={formValues.detail}
          onChange={({ detail }) => setFormValues({ ...formValues, detail: detail.value })}
        />
      </RadFormField>
      <RadCheckbox
        checked={formValues.confirm}
        onChange={({ detail }) => {
          setFormValues({ ...formValues, confirm: detail.checked })
        }}
      >
        Receive an email confirmation of your submission.
      </RadCheckbox>
      <RadBox>Fields marked with a <span style={{ color: 'red' }}>*</span> are required.</RadBox>

      <RadDivider />

      <RadBox float='right'>
        <RadSpaceBetween direction='horizontal' size='xxs'>
          <RadButton variant='link' onClick={() => setFormValues({})}>Cancel</RadButton>
          <RadButton
            variant='primary'
            onClick={() => submitQuestion()}
            disabled={isNullOrWhitespace(formValues.question)}
          >
            Submit Question
          </RadButton>
        </RadSpaceBetween>
      </RadBox>
    </RadSpaceBetween>
  )
}
