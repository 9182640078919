import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RadBox } from './RadBox'
import { RadButton } from './RadButton'

// This utilizes other RAD components
// See each for links to their documentation
export function RadNotFound () {
  const navigate = useNavigate()

  function goHome () {
    navigate('/')
  }

  return (
    <RadBox textAlign='center' padding={{ top: 'xxl' }}>
      <RadBox
        padding={{ top: 'xxl', bottom: 'xxl' }}
        fontSize='display-l'
        color='text-body-secondary'
        fontWeight='bold'
      >
        Not found.
      </RadBox>
      <RadButton onClick={goHome}>Home</RadButton>
    </RadBox>
  )
}
