
    import './styles.scoped.css';
    export default {
  "placeholder": "awsui_placeholder_dwuol_1sd76_141",
  "item": "awsui_item_dwuol_1sd76_146",
  "checkbox": "awsui_checkbox_dwuol_1sd76_150",
  "filter": "awsui_filter_dwuol_1sd76_159",
  "trigger": "awsui_trigger_dwuol_1sd76_164",
  "layout-strut": "awsui_layout-strut_dwuol_1sd76_170",
  "list-bottom": "awsui_list-bottom_dwuol_1sd76_176",
  "selected-icon": "awsui_selected-icon_dwuol_1sd76_180",
  "show-label-tag": "awsui_show-label-tag_dwuol_1sd76_184",
  "inline-token-trigger": "awsui_inline-token-trigger_dwuol_1sd76_188",
  "inline-token-list": "awsui_inline-token-list_dwuol_1sd76_195",
  "inline-token": "awsui_inline-token_dwuol_1sd76_188",
  "visual-refresh": "awsui_visual-refresh_dwuol_1sd76_225",
  "inline-token-hidden-placeholder": "awsui_inline-token-hidden-placeholder_dwuol_1sd76_232",
  "inline-token-counter": "awsui_inline-token-counter_dwuol_1sd76_238",
  "inline-token-trigger--disabled": "awsui_inline-token-trigger--disabled_dwuol_1sd76_242",
  "inline-label-trigger-wrapper": "awsui_inline-label-trigger-wrapper_dwuol_1sd76_248",
  "inline-label-wrapper": "awsui_inline-label-wrapper_dwuol_1sd76_252",
  "inline-label": "awsui_inline-label_dwuol_1sd76_248",
  "inline-label-disabled": "awsui_inline-label-disabled_dwuol_1sd76_274",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_dwuol_1sd76_282"
};
  