import React from 'react'
import Badge from '@cloudscape-design/components-themed/badge'
import { RadBox } from './RadBox'

// https://cloudscape.design/components/badge
export function RadBadge ({ key, ...rest }) {
  return (
    // We can't customize 'default' so we use 'blue' instead because we need a lighter grey than
    // what's defined for 'default'
    <Badge color='blue' key={key}>
      <RadBox variant='small' color='text-label' key={key} {...rest} />
    </Badge>
  )
}
