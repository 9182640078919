import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function SectorDetail () {
  const navigate = useNavigate()
  const { sectorId } = useParams()
  const { data: sector } = useGet(`/api/sector/${sectorId}`)
  const confirmDelete = useConfirm(
    'Deactivate and Hide Sector?',
    'Deactivate and Hide Sector? This sector will no longer appear in the application.',
    'Deactivate and Hide',
    () => { remove() }
  )
  const remove = useDelete(`/api/sector/${sectorId}`, () => { navigate('/sector') })

  function edit () {
    navigate('edit')
  }

  if (sector != null) {
    return (
      <RadAppLayout
        name={sector.name}
        contentHeader={
          <RadHeader
            variant='h1'
            description={sector.email ? <RadLink href={`mailto:${sector.email}`}>{sector.email}</RadLink> : ''}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Deactivate and Hide</RadButton>
              </RadSpaceBetween>
            }
          >
            {sector.name}
          </RadHeader>
        }
      />
    )
  }
}
