import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from './firebase'

export function SignOut () {
  const navigate = useNavigate()

  useEffect(() => {
    auth.signOut()
    navigate('/')
  }, [])

  return null
}
