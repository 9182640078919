import { useState, useEffect, useContext } from 'react'
import { AppContext } from './../App'

export function useGet (uri, primary) {
  const { user, loadingCount, setLoadingCount, notFound, setNotFound, setPermissionDenied, reloadCounter, error, setError } = useContext(AppContext)
  const [data, setData] = useState(null)
  const [count, setCount] = useState(null)
  const [total, setTotal] = useState(null)
  const [info, setInfo] = useState(null)

  useEffect(() => {
    (async () => {
      if (uri) {
        setLoadingCount(count => count + 1)
        const token = await user.getIdToken()
        const response = await fetch(uri, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        })
        let text
        let json
        switch (response.status) {
          case 200:
            try {
              json = await response.json()
              setData(json)
            } catch { }
            setCount(response.headers.get('count'))
            setTotal(response.headers.get('total'))
            setInfo(response.headers.get('info'))
            if (primary) {
              setNotFound(false)
              setError(null)
              setPermissionDenied(null)
            }
            break
          case 403:
            text = await response.text()
            setPermissionDenied(text)
            if (primary) {
              setNotFound(false)
              setData(null)
            }
            break
          case 404:
            if (primary) {
              setNotFound(true)
              setData(null)
            }
            break
          case 500:
            console.log(response.statusText)
            setError({ code: response.statusText })
            if (primary) {
              setNotFound(false)
              setPermissionDenied(null)
            }
            break
          default:
        }
        setLoadingCount(count => count - 1)
      } else {
        setError(null)
        setData(null)
      }
    })()
  }, [uri, primary, reloadCounter, setLoadingCount, setNotFound, setPermissionDenied, user, setError])

  return { data, count, total, info, error, isLoading: loadingCount > 0, notFound }
}
