import React, { useContext, useEffect } from 'react'
import Form from '@cloudscape-design/components-themed/form'
import { AppContext } from './../App'

// https://cloudscape.design/components/form
export function RadForm ({ ...rest }) {
  const { error } = useContext(AppContext)

  useEffect(() => {
    if (error != null) {
      const element = document.querySelector('[data-error="true"]')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [error])

  return (
    <Form
      errorText={error?.message ?? error?.code}
      {...rest}
    />
  )
}
