
    import './styles.scoped.css';
    export default {
  "toggle": "awsui_toggle_1uo6m_dgeyf_189",
  "drawer-triggers": "awsui_drawer-triggers_1uo6m_dgeyf_195",
  "drawer": "awsui_drawer_1uo6m_dgeyf_195",
  "drawer-mobile": "awsui_drawer-mobile_1uo6m_dgeyf_205",
  "drawer-closed": "awsui_drawer-closed_1uo6m_dgeyf_208",
  "drawer-content": "awsui_drawer-content_1uo6m_dgeyf_215",
  "drawer-content-clickable": "awsui_drawer-content-clickable_1uo6m_dgeyf_229",
  "drawer-resize-content": "awsui_drawer-resize-content_1uo6m_dgeyf_239",
  "drawer-content-wrapper": "awsui_drawer-content-wrapper_1uo6m_dgeyf_244",
  "drawer-triggers-wrapper": "awsui_drawer-triggers-wrapper_1uo6m_dgeyf_248",
  "drawer-trigger": "awsui_drawer-trigger_1uo6m_dgeyf_195",
  "drawer-trigger-active": "awsui_drawer-trigger-active_1uo6m_dgeyf_267",
  "resize-handle-wrapper": "awsui_resize-handle-wrapper_1uo6m_dgeyf_275",
  "hide": "awsui_hide_1uo6m_dgeyf_285"
};
  