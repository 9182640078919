
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_lm6vo_1lard_149",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_1lard_156",
  "layout": "awsui_layout_lm6vo_1lard_161",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_1lard_167",
  "layout-main": "awsui_layout-main_lm6vo_1lard_171",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_1lard_177",
  "unfocusable": "awsui_unfocusable_lm6vo_1lard_182",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_1lard_186",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_1lard_191",
  "content-wrapper": "awsui_content-wrapper_lm6vo_1lard_195",
  "content-overlapped": "awsui_content-overlapped_lm6vo_1lard_199",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_1lard_203"
};
  