import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadHeader } from '../common/RadHeader'
import { OrganizationEditContent } from './OrganizationEditContent'

export function OrganizationEdit () {
  const { organizationId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: organization } = useGet(organizationId ? `/api/organization/${organizationId}` : null)

  let items
  if (organization == null) {
    items = [
      { text: 'Home', href: '/' },
      { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
      { text: 'New Organization' }
    ]
  } else {
    items = [
      { text: 'Home', href: '/' },
      { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
      { text: organization.name, href: `/organization/${organizationId}` },
      { text: 'Edit' }
    ]
  }

  return (
    <RadAppLayout
      breadcrumbs={
        <RadBreadcrumbGroup
          items={items}
          ariaLabel='Breadcrumbs'
        />
      }
      contentHeader={
        <RadHeader variant='h1'>
          {organization != null ? 'Edit Organization' : 'New Organization'}
        </RadHeader>
        }
      content={
        <OrganizationEditContent organization={organization} formValues={formValues} setFormValues={setFormValues} />
        }
    />
  )
}
