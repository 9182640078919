import React, { useContext } from 'react'
import Container from '@cloudscape-design/components-themed/container'
import { AppContext } from './../App'
import { RadBox } from './RadBox'

// https://cloudscape.design/components/container
export function RadContainer ({ field, header, ...rest }) {
  const { error } = useContext(AppContext)
  const errorText = field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null

  const headerWithError = (
    <div data-error='true'>
      {header}
      <RadBox color='text-status-error'>{errorText}</RadBox>
    </div>
  )

  return (
    <Container
      header={errorText != null ? headerWithError : header}
      {...rest}
    />
  )
}
