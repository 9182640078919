
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_1gyv0_141",
  "button": "awsui_button_fvjdu_1gyv0_184",
  "dots": "awsui_dots_fvjdu_1gyv0_185",
  "button-disabled": "awsui_button-disabled_fvjdu_1gyv0_213",
  "arrow": "awsui_arrow_fvjdu_1gyv0_218",
  "page-number": "awsui_page-number_fvjdu_1gyv0_228",
  "button-current": "awsui_button-current_fvjdu_1gyv0_234",
  "page-item": "awsui_page-item_fvjdu_1gyv0_251",
  "root-disabled": "awsui_root-disabled_fvjdu_1gyv0_269"
};
  