import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useConfirm } from '../hooks/useConfirm'
import { useDelete } from '../hooks/useDelete'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function TagDetail () {
  const navigate = useNavigate()
  const { tagId } = useParams()
  const { data: tag } = useGet(`/api/tag/${tagId}`)
  const confirmDelete = useConfirm('Delete tag?', 'Delete tag permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/tag/${tagId}`, () => navigate('/tag'))

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (tag != null) {
    return (
      <RadAppLayout
        name={tag.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={tag.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {tag.name}
          </RadHeader>
        }
      />
    )
  }
}
