
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_7nfqu_14dgv_149",
  "has-adaptive-widths-default": "awsui_has-adaptive-widths-default_7nfqu_14dgv_198",
  "has-adaptive-widths-dashboard": "awsui_has-adaptive-widths-dashboard_7nfqu_14dgv_213",
  "navigation": "awsui_navigation_7nfqu_14dgv_228",
  "tools": "awsui_tools_7nfqu_14dgv_229",
  "global-tools": "awsui_global-tools_7nfqu_14dgv_230",
  "tools-open": "awsui_tools-open_7nfqu_14dgv_268",
  "split-panel-side": "awsui_split-panel-side_7nfqu_14dgv_281",
  "split-panel-bottom": "awsui_split-panel-bottom_7nfqu_14dgv_288",
  "panel-hidden": "awsui_panel-hidden_7nfqu_14dgv_301",
  "toolbar-container": "awsui_toolbar-container_7nfqu_14dgv_311",
  "notifications-container": "awsui_notifications-container_7nfqu_14dgv_317",
  "notifications-background": "awsui_notifications-background_7nfqu_14dgv_321",
  "main-landmark": "awsui_main-landmark_7nfqu_14dgv_332",
  "main": "awsui_main_7nfqu_14dgv_332",
  "main-disable-paddings": "awsui_main-disable-paddings_7nfqu_14dgv_341",
  "content-header": "awsui_content-header_7nfqu_14dgv_351",
  "content": "awsui_content_7nfqu_14dgv_351",
  "unfocusable-mobile": "awsui_unfocusable-mobile_7nfqu_14dgv_361"
};
  