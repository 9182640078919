import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { ProjectEditContent } from './ProjectEditContent'

export function ProjectEdit () {
  const { projectId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: project } = useGet(projectId ? `/api/project/${projectId}` : null, true)

  return (
    <RadAppLayout
      name={project?.title}
      contentHeader={
        <RadHeader variant='h1'>
          {project != null ? 'Edit Project' : 'New Project'}
        </RadHeader>
      }
      content={
        <ProjectEditContent project={project} formValues={formValues} setFormValues={setFormValues} />
      }
    />
  )
}
