import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadPagination } from '../common/RadPagination'
import { RadPopover } from '../common/RadPopover'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { formatDate } from '../common/utilities'

export function NewRecords () {
  const { loadingCount } = useContext(AppContext)
  const PAGE_LENGTH = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [fromDate, setFromDate] = useState(searchParams.get('from') ?? '')
  const [toDate, setToDate] = useState(searchParams.get('to') ?? '')
  const [sortOrder, setSortOrder] = useState(searchParams.get('sort') ?? 'count')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [exporting, setExporting] = useState(false)
  const { data, count } = useGet([
    '/api/report/new-records',
    `?limit=${PAGE_LENGTH}` +
    `&fromDate=${fromDate}`,
    `&toDate=${toDate}`,
    `&offset=${(currentPageIndex - 1) * PAGE_LENGTH}`,
    `&sortOrder=${sortOrder}`
  ].join(''))
  const { data: exportData } = useGet(
    exporting
      ? [
          '/api/report/new-records',
          '?limit=5000' +
          `&fromDate=${fromDate}`,
          `&toDate=${toDate}`,
          `&sortOrder=${sortOrder}`
        ].join('')
      : null
  )

  useEffect(() => {
    if (exportData != null) {
      exportToExcel()
      setExporting(false)
    }
  }, [exportData])

  function exportToExcel () {
    const rows = exportData
      .map(x => Object.keys(x)
        .filter(y => y !== 'total')
        .map(y => x[y])
      )
    rows.unshift(['Type', 'Count'])

    const worksheet = XLSX.utils.aoa_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'New Records')

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `new-records_${year}_${month}_${date}.xlsx`
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  if (
    data != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              {
                text: (
                  <div className='breadcrumb-container'>
                    <span style={{ fontWeight: '400' }}>Reports</span>
                    <div className='breadcrumb-carat' />
                    <span>New Records</span>
                  </div>
                )
              }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader
            actions={
              <RadButton onClick={() => setExporting(true)}>Export</RadButton>
            }
            counter={'(' + count + ')'}
            variant='h1'
          >
            New Records
          </RadHeader>
        }
        content={
          <RadTable
            columnDefinitions={[
              {
                id: 'type',
                header: 'Type',
                cell: e => e.type
              },
              {
                id: 'count',
                header: 'Count',
                cell: e => Number(e.count).toLocaleString()
              }
            ]}
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 6 } },
                  { colspan: { default: 12, xs: 6 } }
                ]}
              >
                <RadSpaceBetween size='xs'>
                  <RadBox color='text-status-inactive'>
                    {fromDate === '' && toDate === '' && 'All time '}
                    {fromDate !== '' && toDate !== '' && `${formatDate(fromDate)} to ${formatDate(toDate)} `}
                    {fromDate !== '' && toDate === '' && `After ${formatDate(fromDate)} `}
                    {fromDate === '' && toDate !== '' && `Before ${formatDate(toDate)} `}
                    {`sorted ${sortOrder === 'count' ? 'by count' : 'alphabetically'}`}
                  </RadBox>
                </RadSpaceBetween>
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <SortPopover
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    setCurrentPageIndex={setCurrentPageIndex}
                    loadingCount={loadingCount}
                  />
                  <FilterPopover
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setCurrentPageIndex={setCurrentPageIndex}
                    loadingCount={loadingCount}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                  />
                </RadSpaceBetween>
              </RadGrid>
            }
            pagination={
              <RadBox padding={{ bottom: 'xxl' }}>
                <RadPagination
                  currentPageIndex={currentPageIndex}
                  pagesCount={Math.ceil(data.length / PAGE_LENGTH)}
                  onChange={({ detail }) => {
                    if (detail.currentPageIndex === 1) {
                      searchParams.delete('page')
                    } else {
                      searchParams.set('page', detail.currentPageIndex)
                    }
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
              </RadBox>
            }
            items={data}
            variant='embedded'
          />
        }
      />
    )
  }
}

function SortPopover ({ searchParams, setSearchParams, sortOrder, setSortOrder, setCurrentPageIndex, loadingCount }) {
  return (
    <RadPopover
      position='bottom'
      size='small'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Sort
          </RadHeader>
          <RadRadioGroup
            onChange={({ detail }) => {
              setSortOrder(detail.value)
              if (detail.value === 'count') {
                searchParams.delete('sort')
              } else {
                searchParams.set('sort', detail.value)
              }
              searchParams.delete('page')
              setSearchParams(searchParams)
              setCurrentPageIndex(1)
            }}
            items={[
              { value: 'count', label: 'Count', disabled: loadingCount > 0 },
              { value: 'alphabetical', label: 'Alphabetical', disabled: loadingCount > 0 }
            ]}
            value={sortOrder}
          />
        </RadSpaceBetween>
      }
    >
      <RadButton className='sort' />
    </RadPopover>
  )
}

function FilterPopover ({
  searchParams,
  setSearchParams,
  setCurrentPageIndex,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  loadingCount
}) {
  return (
    <RadPopover
      position='bottom'
      size='large'
      triggerType='custom'
      content={
        <RadSpaceBetween size='xs'>
          <RadHeader
            variant='h2'
          >
            Filter
          </RadHeader>
          <RadFormField label='From'>
            <RadInput
              onChange={({ detail }) => {
                if (detail.value === '') {
                  searchParams.delete('from')
                } else {
                  searchParams.set('from', detail.value)
                }
                setFromDate(detail.value)
                searchParams.delete('page')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
              }}
              type='date'
              value={fromDate ?? ''}
            />
          </RadFormField>
          <RadFormField label='To'>
            <RadInput
              onChange={({ detail }) => {
                if (detail.value === '') {
                  searchParams.delete('to')
                } else {
                  searchParams.set('to', detail.value)
                }
                setToDate(detail.value)
                searchParams.delete('page')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
              }}
              type='date'
              value={toDate ?? ''}
            />
          </RadFormField>
          <RadBox float='right' padding={{ top: 'l', bottom: 'xxs' }}>
            <RadButton
              onClick={() => {
                searchParams.delete('page')
                searchParams.delete('from')
                searchParams.delete('to')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
                setFromDate('')
                setToDate('')
              }}
            >
              Clear All Filters
            </RadButton>
          </RadBox>
        </RadSpaceBetween>
      }
    >
      <RadButton className='filter' />
    </RadPopover>
  )
}
