import React, { useEffect, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { AppContext } from '../App'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadPopover } from '../common/RadPopover'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadMultiselect } from '../common/RadMultiselect'
import { EntityModal } from '../shared/EntityModal'
import { getShortText } from '../common/utilities'

export function SearchList () {
  const isMobile = useIsMobile()
  const { loadingCount } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [sortOrder, setSortOrder] = useState(searchParams.get('sort') ?? 'relevance')
  const [sectorId, setSectorId] = useState(searchParams.get('sector') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tags') ?? '')
  const [resourceTypeId, setResourceTypeId] = useState(searchParams.get('resource-type') ?? '')
  const [organizationTypeId, setOrganizationTypeId] = useState(searchParams.get('organization-type') ?? '')
  const [projectTypeId, setProjectTypeId] = useState(searchParams.get('project-type') ?? '')

  const organizationCollaborativePriorityAreaId = searchParams.get('organization-collaborative-priority-area') ?? ''
  const [projectPriorityAreaId, setProjectPriorityAreaId] = useState(searchParams.get('project-priority-area') ?? '')

  const [organizationPriorityPopulationIds, setOrganizationPriorityPopulationIds] = useState(searchParams.get('organization-priority-populations') ?? '')
  const [projectPriorityPopulationIds, setProjectPriorityPopulationIds] = useState(searchParams.get('project-priority-populations') ?? '')
  const [resourcePriorityPopulationIds, setResourcePriorityPopulationIds] = useState(searchParams.get('resource-priority-populations') ?? '')

  const [dashFundingProgramId, setDashFundingProgramId] = useState(searchParams.get('dash-funding-program') ?? '')

  const [projectRows, setProjectRows] = useState(3)
  const [resourceRows, setResourceRows] = useState(3)
  const [organizationCollaborativeRows, setOrganizationCollaborativeRows] = useState(3)

  const { data: dashFundingProgramOptions } = useGet('/api/option/type?entity=DASH_funding_program&&all=true')
  const { data: organizationTypeOptions } = useGet('/api/option/type?entity=organization&all=true')
  const { data: projectTypeOptions } = useGet('/api/option/type?entity=project_use_case&all=true')
  const { data: resourceTypeOptions } = useGet('/api/option/type?entity=resource&all=true')
  const { data: sectorOptions } = useGet('/api/option/sector?all=true')
  const { data: tagOptions } = useGet('/api/option/tag?all=true')
  const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const { data: targetAreaOptions } = useGet(
    targetAreaFilteringText !== ''
      ? `/api/option/target-area?search=${targetAreaFilteringText}&all=true`
      : `/api/option/target-area?search=${targetAreaFilteringText}&all=true&limit=0`
  )
  const [targetPopulationFilteringText, setTargetPopulationFilteringText] = useState('')
  const { data: targetPopulationOptions } = useGet(`/api/option/priority-population?search=${targetPopulationFilteringText}&limit=500`)

  const { data: projectPriorityArea } = useGet(projectPriorityAreaId !== '' ? `/api/target-area/${projectPriorityAreaId}` : null)
  const { data: organizationPriorityArea } = useGet(organizationCollaborativePriorityAreaId !== '' ? `/api/target-area/${organizationCollaborativePriorityAreaId}` : null)
  const { data: priorityPopulations } = useGet('/api/option/priority-population?limit=500')

  const { data: projects, count: projectCount } = useGet([
    '/api/search/project',
    `?search=${searchText}`,
    `&rows=${projectRows}`,
    `&sortOrder=${sortOrder}`,
    `&sectorId=${sectorId}`,
    `&tagIds=${tagIds ?? ''}`,
    `&projectTypeId=${projectTypeId}`,
    `&resourceTypeId=${resourceTypeId}`,
    `&priorityAreaId=${projectPriorityAreaId}`,
    `&priorityPopulationIds=${projectPriorityPopulationIds}`,
    `&dashFundingProgramId=${dashFundingProgramId}`
  ].join(''))
  const { data: resources, count: resourceCount } = useGet([
    '/api/search/resource',
    `?search=${searchText}`,
    `&rows=${resourceRows}`,
    `&sortOrder=${sortOrder}`,
    `&sectorId=${sectorId}`,
    `&tagIds=${tagIds ?? ''}`,
    `&projectTypeId=${projectTypeId}`,
    `&resourceTypeId=${resourceTypeId}`,
    `&priorityPopulationIds=${resourcePriorityPopulationIds}`,
    `&dashFundingProgramId=${dashFundingProgramId}`
  ].join(''))
  const { data: organizationsAndCollaboratives, count: organizationAndCollaborativeCount } = useGet([
    '/api/search/organization-collaborative',
    `?search=${searchText}`,
    `&rows=${organizationCollaborativeRows}`,
    `&sortOrder=${sortOrder}`,
    `&sectorId=${sectorId}`,
    `&tagIds=${tagIds ?? ''}`,
    `&projectTypeId=${projectTypeId}`,
    `&resourceTypeId=${resourceTypeId}`,
    `&organizationTypeId=${organizationTypeId}`,
    `&priorityAreaId=${organizationCollaborativePriorityAreaId}`,
    `&priorityPopulationIds=${organizationPriorityPopulationIds}`
  ].join(''))
  const { data: tags } = useGet('/api/tag?limit=5000')

  useEffect(() => {
    setSearchText(searchParams.get('search') ?? '')
  }, [searchParams])

  if (
    projects != null &&
    resources != null &&
    organizationsAndCollaboratives != null &&
    tags != null &&
    dashFundingProgramOptions != null &&
    organizationTypeOptions != null &&
    projectTypeOptions != null &&
    resourceTypeOptions != null &&
    sectorOptions != null &&
    tagOptions != null &&
    // targetAreaOptions != null
    targetPopulationOptions != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Search', href: '/search' }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentType='dashboard'
        contentHeader={
          <RadHeader
            variant='h1'
          // description={info != null && JSON.parse(info).degraded === true ? '* Oops! Our advanced search took a coffee break. We\'ve switched to classic search for now. Thanks for your patience!' : null}
          >
            Search
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadGrid
              gridDefinition={[
                { colspan: { default: 12, xs: 8 } },
                { colspan: { default: 12, xs: 4 } }
              ]}
            >
              <RadInput2
                initialValue={searchText}
                placeholder='Type what you are looking for'
                type='search'
                onEnter={({ detail }) => {
                  setSearchText(detail.value)
                  searchParams.set('search', detail.value)
                  setSearchParams(searchParams)
                  setProjectRows(3)
                  setResourceRows(3)
                  setOrganizationCollaborativeRows(3)
                }}
              />
              <RadSpaceBetween direction='horizontal' size='s'>
                <SortPopover
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  loadingCount={loadingCount}
                />
                <FilterPopover
                  dashFundingProgramId={dashFundingProgramId}
                  dashFundingProgramOptions={dashFundingProgramOptions}
                  loadingCount={loadingCount}
                  organizationPriorityArea={organizationPriorityArea}
                  organizationPriorityAreaId={organizationCollaborativePriorityAreaId}
                  organizationPriorityPopulationIds={organizationPriorityPopulationIds}
                  organizationTypeId={organizationTypeId}
                  organizationTypeOptions={organizationTypeOptions}
                  priorityPopulations={priorityPopulations}
                  projectPriorityArea={projectPriorityArea}
                  projectPriorityAreaId={projectPriorityAreaId}
                  projectPriorityPopulationIds={projectPriorityPopulationIds}
                  projectTypeId={projectTypeId}
                  projectTypeOptions={projectTypeOptions}
                  resourcePriorityPopulationIds={resourcePriorityPopulationIds}
                  resourceTypeId={resourceTypeId}
                  resourceTypeOptions={resourceTypeOptions}
                  searchParams={searchParams}
                  sectorId={sectorId}
                  sectorOptions={sectorOptions}
                  setDashFundingProgramId={setDashFundingProgramId}
                  setOrganizationPriorityPopulationIds={setOrganizationPriorityPopulationIds}
                  setOrganizationTypeId={setOrganizationTypeId}
                  setProjectPriorityAreaId={setProjectPriorityAreaId}
                  setProjectPriorityPopulationIds={setProjectPriorityPopulationIds}
                  setProjectTypeId={setProjectTypeId}
                  setResourcePriorityPopulationIds={setResourcePriorityPopulationIds}
                  setResourceTypeId={setResourceTypeId}
                  setSearchParams={setSearchParams}
                  setSectorId={setSectorId}
                  setTagIds={setTagIds}
                  setTargetAreaFilteringText={setTargetAreaFilteringText}
                  setTargetPopulationFilteringText={setTargetPopulationFilteringText}
                  tagIds={tagIds}
                  tagOptions={tagOptions}
                  targetAreaFilteringText={targetAreaFilteringText}
                  targetAreaOptions={targetAreaOptions ?? []}
                  targetPopulationFilteringText={targetPopulationFilteringText}
                  targetPopulationOptions={targetPopulationOptions}
                />
              </RadSpaceBetween>
            </RadGrid>
            <RadSpaceBetween size='s'>

              {(sectorId !== '' || tagIds !== '') &&
                <RadBox color='text-body-secondary'>
                  <RadSpaceBetween direction='horizontal' size='xs'>
                    <div>Filter(s):</div>
                    {[
                      sectorId !== '' ? <div>{`Sector: ${sectorOptions.find(x => x.value === sectorId)?.label}`}</div> : null,
                      tagIds !== '' ? <div>{`Tags: ${tagOptions.filter(x => tagIds.split('-').includes(x.value)).map(x => x.label).join(', ')}`}</div> : null
                    ]
                      .filter(x => x !== null)}
                  </RadSpaceBetween>
                </RadBox>}

              <RadHeader
                counter={'(' + projectCount + ')'}
                description={
                  projectTypeId !== '' || projectPriorityArea != null || projectPriorityPopulationIds !== '' || dashFundingProgramId !== ''
                    ? 'Filter(s): ' +
                    [
                      projectTypeId !== '' ? 'Type: ' + projectTypeOptions.find(x => x.value === projectTypeId)?.label : null,
                      dashFundingProgramId !== '' ? 'DASH Funding Program: ' + dashFundingProgramOptions.find(x => x.value === dashFundingProgramId)?.label : null,
                      projectPriorityArea != null ? 'Priority Area: ' + projectPriorityArea.name : null,
                      projectPriorityPopulationIds !== ''
                        ? 'Priority Populations: ' + priorityPopulations
                          .filter(x => projectPriorityPopulationIds.split('-').includes(x.value)).map(x => x.label).join(', ')
                        : null
                    ]
                      .filter(x => x !== null)
                      .join(', ')
                    : null
                }
                variant='h2'
              >
                Projects
              </RadHeader>
              {projects.map((x) =>
                <RadSpaceBetween key={x.type + '-' + x.id} size='xxs'>
                  {isMobile &&
                    <RadLink className='font-weight-700' fontSize='heading-s' href={`/${x.type}/${x.id}`}>{x.name}</RadLink>}
                  {!isMobile &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'project', id: x.id })}
                      className='font-weight-700 font-size-16'
                      variant='inline-link'
                    >
                      {x.name}
                    </RadButton>}
                  {x.description != null && <RadBox color='text-status-inactive'>{getShortText(x.description)}</RadBox>}
                </RadSpaceBetween>
              )}
              {projects.length === 0 &&
                <RadBox color='text-status-inactive'>No records meet this search criteria</RadBox>}
              {projectRows < projectCount &&
                <RadButton
                  variant='inline-link'
                  iconName='add-plus'
                  onClick={() => {
                    setProjectRows(projectRows + 10)
                  }}
                >
                  Show more results
                </RadButton>}
            </RadSpaceBetween>

            <RadSpaceBetween size='s'>
              <RadHeader
                counter={'(' + resourceCount + ')'}
                description={
                  resourceTypeId !== '' || resourcePriorityPopulationIds !== ''
                    ? 'Filter(s): ' +
                    [
                      resourceTypeId !== '' ? 'Type: ' + resourceTypeOptions.find(x => x.value === resourceTypeId)?.label : null,
                      resourcePriorityPopulationIds !== ''
                        ? 'Priority Populations: ' + priorityPopulations
                          .filter(x => resourcePriorityPopulationIds.split('-').includes(x.value)).map(x => x.label).join(', ')
                        : null
                    ]
                      .filter(x => x !== null)
                      .join(', ')
                    : null
                }
                variant='h2'
              >
                Resources
              </RadHeader>
              {resources.map((x) =>
                <RadSpaceBetween key={x.type + '-' + x.id} size='xxs'>
                  {isMobile &&
                    <RadLink className='font-weight-700' fontSize='heading-s' href={`/${x.type}/${x.id}`}>{x.name}</RadLink>}
                  {!isMobile &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'resource', id: x.id })}
                      className='font-weight-700 font-size-16'
                      variant='inline-link'
                    >
                      {x.name}
                    </RadButton>}
                  {x.description != null && <RadBox color='text-status-inactive'>{getShortText(x.description)}</RadBox>}
                </RadSpaceBetween>
              )}
              {resources.length === 0 &&
                <RadBox color='text-status-inactive'>No records meet this search criteria</RadBox>}
              {resourceRows < resourceCount &&
                <RadButton
                  variant='inline-link'
                  iconName='add-plus'
                  onClick={() => {
                    setResourceRows(resourceRows + 10)
                  }}
                >
                  Show more results
                </RadButton>}
            </RadSpaceBetween>

            <RadSpaceBetween size='s'>
              <RadHeader
                counter={'(' + organizationAndCollaborativeCount + ')'}
                variant='h2'
                description={
                  organizationTypeId !== '' || organizationPriorityArea != null || organizationPriorityPopulationIds !== ''
                    ? 'Filter(s): ' +
                    [
                      organizationTypeId !== '' ? 'Organization Type: ' + organizationTypeOptions.find(x => x.value === organizationTypeId)?.label : null,
                      organizationPriorityArea != null ? 'Organization Priority Area: ' + organizationPriorityArea.name : null,
                      organizationPriorityPopulationIds !== ''
                        ? 'Organization Priority Populations: ' + priorityPopulations
                          .filter(x => organizationPriorityPopulationIds.split('-').includes(x.value)).map(x => x.label).join(', ')
                        : null
                    ]
                      .filter(x => x !== null)
                      .join(', ')
                    : null
                }
              >
                Organizations & Collaboratives
              </RadHeader>
              {organizationsAndCollaboratives.map((x) =>
                <RadSpaceBetween key={x.type + '-' + x.id} size='xs'>
                  {isMobile &&
                    <RadLink className='font-weight-700' fontSize='heading-s' href={`/${x.type}/${x.id}`}>{x.name}</RadLink>}
                  {!isMobile &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: x.type, id: x.id })}
                      className='font-weight-700 font-size-16'
                      variant='inline-link'
                    >
                      {x.name}
                    </RadButton>}
                  {x.description != null && <RadBox color='text-status-inactive'>{getShortText(x.description)}</RadBox>}
                </RadSpaceBetween>
              )}
              {organizationsAndCollaboratives.length === 0 &&
                <RadBox color='text-status-inactive'>No records meet this search criteria</RadBox>}
              {organizationCollaborativeRows < organizationAndCollaborativeCount &&
                <RadButton
                  variant='inline-link'
                  iconName='add-plus'
                  onClick={() => {
                    setOrganizationCollaborativeRows(organizationCollaborativeRows + 10)
                  }}
                >
                  Show more results
                </RadButton>}
            </RadSpaceBetween>
            <EntityModal
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              closeModal={() => setSelectedEntity(null)}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function SortPopover ({ searchParams, setSearchParams, sortOrder, setSortOrder, loadingCount }) {
  return (
    <RadPopover
      position='bottom'
      size='small'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Sort
          </RadHeader>
          <RadRadioGroup
            onChange={({ detail }) => {
              setSortOrder(detail.value)
              if (detail.value === 'relevance') {
                searchParams.delete('sort')
              } else {
                searchParams.set('sort', detail.value)
              }
              setSearchParams(searchParams)
            }}
            items={[
              { value: 'relevance', label: 'Relevance', disabled: loadingCount > 0 },
              { value: 'alphabetical', label: 'Alphabetical', disabled: loadingCount > 0 },
              { value: 'recent', label: 'Recently Updated', disabled: loadingCount > 0 }
            ]}
            value={sortOrder}
          />
        </RadSpaceBetween>
      }
    >
      <RadButton className='sort' />
    </RadPopover>
  )
}

function FilterPopover ({
  dashFundingProgramId,
  dashFundingProgramOptions,
  loadingCount,
  organizationPriorityArea,
  organizationPriorityAreaId,
  organizationTypeId,
  organizationTypeOptions,
  organizationPriorityPopulationIds,
  priorityPopulations,
  projectPriorityArea,
  projectPriorityAreaId,
  projectPriorityPopulationIds,
  projectTypeId,
  projectTypeOptions,
  resourcePriorityPopulationIds,
  resourceTypeId,
  resourceTypeOptions,
  searchParams,
  sectorId,
  sectorOptions,
  setDashFundingProgramId,
  setOrganizationPriorityPopulationIds,
  setOrganizationTypeId,
  setProjectPriorityAreaId,
  setProjectTypeId,
  setProjectPriorityPopulationIds,
  setResourcePriorityPopulationIds,
  setResourceTypeId,
  setSearchParams,
  setSectorId,
  setTagIds,
  setTargetAreaFilteringText,
  setTargetPopulationFilteringText,
  tagIds,
  tagOptions,
  targetAreaFilteringText,
  targetAreaOptions,
  targetPopulationFilteringText,
  targetPopulationOptions
}) {
  const disabled = { collaboratives: false, projects: false, organizations: false, resources: false }
  if (organizationTypeId !== '') {
    disabled.collaboratives = true
    disabled.projects = true
    disabled.resources = true
  }
  if (projectTypeId !== '' || dashFundingProgramId !== '') {
    disabled.organizations = true
    disabled.resources = true
  }
  if (resourceTypeId !== '') {
    disabled.organizations = true
    disabled.collaboratives = true
    disabled.projects = true
  }

  return (
    <RadPopover
      position='bottom'
      size='large'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Filter
          </RadHeader>
          <RadSpaceBetween size='xxs'>
            <RadBox variant='awsui-key-label'>
              All Records
            </RadBox>
            <RadSelect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOption={sectorOptions.find(x => x.value === sectorId) ?? sectorOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('sector')
                } else {
                  searchParams.set('sector', detail.selectedOption.value)
                }
                setSectorId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              options={sectorOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a record type'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('tags')
                } else {
                  searchParams.set('tags', ids)
                }
                setTagIds(ids)
                setSearchParams(searchParams)
              }}
              options={tagOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose tags'
              keepOpen={false}
            />
          </RadSpaceBetween>
          <RadSpaceBetween size='xxs'>
            <RadBox variant='awsui-key-label'>
              Project Filters
            </RadBox>
            <RadSelect
              disabled={loadingCount > 0 || (disabled.collaboratives && disabled.projects && disabled.organizations)}
              filteringType='manual'
              selectedOption={projectPriorityArea != null ? { value: projectPriorityArea.id, label: projectPriorityArea.name } : null}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('project-priority-area')
                } else {
                  searchParams.set('project-priority-area', detail.selectedOption.value)
                }
                setProjectPriorityAreaId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetAreaFilteringText(detail.filteringText)
              }}
              options={targetAreaOptions ?? []}
              selectedAriaLabel='Selected'
              filteringPlaceholder='Enter zip code or location name'
              placeholder='Choose a priority area'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0 || (disabled.collaboratives && disabled.projects && disabled.resources)}
              filteringType='manual'
              selectedOptions={priorityPopulations.filter((x) => projectPriorityPopulationIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('project-priority-populations')
                } else {
                  searchParams.set('project-priority-populations', ids)
                }
                setProjectPriorityPopulationIds(ids)
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetPopulationFilteringText(detail.filteringText)
              }}
              options={targetPopulationOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose priority populations'
              filteringPlaceholder='Search priority populations'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0 || disabled.projects}
              selectedOption={projectTypeOptions.find(x => x.value === projectTypeId) ?? projectTypeOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('project-type')
                } else {
                  searchParams.set('project-type', detail.selectedOption.value)
                }
                setProjectTypeId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              options={projectTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
            <RadSelect
              disabled={loadingCount > 0 || disabled.projects}
              filteringType='auto'
              selectedOption={dashFundingProgramOptions.find(x => x.value === dashFundingProgramId) ?? dashFundingProgramOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('dash-funding-program')
                } else {
                  searchParams.set('dash-funding-program', detail.selectedOption.value)
                }
                setDashFundingProgramId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              options={dashFundingProgramOptions}
              selectedAriaLabel='Selected'
              filteringPlaceholder='Search DASH funding program types'
              empty='No matches found'
            />
          </RadSpaceBetween>
          <RadSpaceBetween size='xxs'>
            <RadBox variant='awsui-key-label'>
              Resource Filters
            </RadBox>
            <RadMultiselect
              disabled={loadingCount > 0 || (disabled.collaboratives && disabled.projects && disabled.resources)}
              filteringType='manual'
              selectedOptions={priorityPopulations.filter((x) => resourcePriorityPopulationIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('resource-priority-populations')
                } else {
                  searchParams.set('resource-priority-populations', ids)
                }
                setResourcePriorityPopulationIds(ids)
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetPopulationFilteringText(detail.filteringText)
              }}
              options={targetPopulationOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose priority populations'
              filteringPlaceholder='Search priority populations'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0 || disabled.resources}
              selectedOption={resourceTypeOptions.find(x => x.value === resourceTypeId) ?? resourceTypeOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('resource-type')
                } else {
                  searchParams.set('resource-type', detail.selectedOption.value)
                }
                setResourceTypeId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              options={resourceTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadSpaceBetween>
          <RadSpaceBetween size='xxs'>
            <RadBox variant='awsui-key-label'>
              Organization & Collaborative Filters
            </RadBox>
            <RadSelect
              disabled={loadingCount > 0 || (disabled.collaboratives && disabled.projects && disabled.organizations)}
              filteringType='manual'
              selectedOption={organizationPriorityArea != null ? { value: organizationPriorityArea.id, label: organizationPriorityArea.name } : null}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('organization-collaborative-priority-area')
                } else {
                  searchParams.set('organization-collaborative-priority-area', detail.selectedOption.value)
                }
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetAreaFilteringText(detail.filteringText)
              }}
              options={targetAreaOptions ?? []}
              selectedAriaLabel='Selected'
              filteringPlaceholder='Enter zip code or location name'
              placeholder='Choose a priority area'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0 || (disabled.collaboratives && disabled.projects && disabled.resources)}
              filteringType='manual'
              selectedOptions={priorityPopulations.filter((x) => organizationPriorityPopulationIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('organization-priority-populations')
                } else {
                  searchParams.set('organization-priority-populations', ids)
                }
                setOrganizationPriorityPopulationIds(ids)
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetPopulationFilteringText(detail.filteringText)
              }}
              options={targetPopulationOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose priority populations'
              filteringPlaceholder='Search priority populations'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0 || disabled.organizations}
              selectedOption={organizationTypeOptions.find(x => x.value === organizationTypeId) ?? organizationTypeOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('organization-type')
                } else {
                  searchParams.set('organization-type', detail.selectedOption.value)
                }
                setOrganizationTypeId(detail.selectedOption.value)
                setSearchParams(searchParams)
              }}
              options={organizationTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadSpaceBetween>
          <RadBox float='right'>
            <RadButton
              onClick={() => {
                searchParams.delete('sector')
                searchParams.delete('tags')
                searchParams.delete('record-type')
                searchParams.delete('resource-type')
                searchParams.delete('organization-type')
                searchParams.delete('project-type')
                searchParams.delete('collaborative-priority-area')
                searchParams.delete('organization-collaborative-priority-area')
                searchParams.delete('project-priority-area')
                searchParams.delete('priority-populations')
                searchParams.delete('dash-funding-program')
                setSearchParams(searchParams)
                setDashFundingProgramId('')
                setOrganizationPriorityPopulationIds('')
                setOrganizationTypeId('')
                setProjectPriorityAreaId('')
                setProjectPriorityPopulationIds('')
                setProjectTypeId('')
                setResourcePriorityPopulationIds('')
                setResourceTypeId('')
                setSectorId('')
                setTagIds('')
              }}
            >
              Clear All Filters
            </RadButton>
          </RadBox>
        </RadSpaceBetween>
      }
    >
      <RadButton className='filter' />
    </RadPopover>
  )
}
