import React from 'react'
import { RadLink } from './RadLink'

export function RadExternalLink ({ href, ...rest }) {
  return (
    <RadLink
      external
      externalIconAriaLabel='Opens in a new tab'
      href={href}
      target='_blank'
      {...rest}
    >
      {href != null ? href.split('/').pop() : ''}
    </RadLink>
  )
}
