
    import './styles.scoped.css';
    export default {
  "flash-with-motion": "awsui_flash-with-motion_1q84n_v72ty_153",
  "enter": "awsui_enter_1q84n_v72ty_153",
  "flash-body": "awsui_flash-body_1q84n_v72ty_167",
  "flash-message": "awsui_flash-message_1q84n_v72ty_167",
  "flash-header": "awsui_flash-header_1q84n_v72ty_167",
  "flash-content": "awsui_flash-content_1q84n_v72ty_168",
  "action-button-wrapper": "awsui_action-button-wrapper_1q84n_v72ty_169",
  "dismiss-button-wrapper": "awsui_dismiss-button-wrapper_1q84n_v72ty_170",
  "flash-icon": "awsui_flash-icon_1q84n_v72ty_193",
  "entering": "awsui_entering_1q84n_v72ty_206",
  "entered": "awsui_entered_1q84n_v72ty_227",
  "exiting": "awsui_exiting_1q84n_v72ty_332",
  "stack": "awsui_stack_1q84n_v72ty_366",
  "animation-running": "awsui_animation-running_1q84n_v72ty_366",
  "item": "awsui_item_1q84n_v72ty_366",
  "flash-list-item": "awsui_flash-list-item_1q84n_v72ty_367",
  "notification-bar": "awsui_notification-bar_1q84n_v72ty_368",
  "collapsed": "awsui_collapsed_1q84n_v72ty_388",
  "animation-ready": "awsui_animation-ready_1q84n_v72ty_388",
  "expanded-only": "awsui_expanded-only_1q84n_v72ty_388",
  "expanded": "awsui_expanded_1q84n_v72ty_388",
  "flash": "awsui_flash_1q84n_v72ty_153",
  "collapsible": "awsui_collapsible_1q84n_v72ty_444",
  "short-list": "awsui_short-list_1q84n_v72ty_450",
  "visual-refresh": "awsui_visual-refresh_1q84n_v72ty_450",
  "status": "awsui_status_1q84n_v72ty_597",
  "header": "awsui_header_1q84n_v72ty_597",
  "item-count": "awsui_item-count_1q84n_v72ty_598",
  "button": "awsui_button_1q84n_v72ty_599",
  "type-count": "awsui_type-count_1q84n_v72ty_632",
  "count-number": "awsui_count-number_1q84n_v72ty_632",
  "icon": "awsui_icon_1q84n_v72ty_666",
  "floating": "awsui_floating_1q84n_v72ty_707",
  "flashbar": "awsui_flashbar_1q84n_v72ty_711",
  "initial-hidden": "awsui_initial-hidden_1q84n_v72ty_772",
  "flash-list": "awsui_flash-list_1q84n_v72ty_367",
  "flash-focus-container": "awsui_flash-focus-container_1q84n_v72ty_794",
  "flash-text": "awsui_flash-text_1q84n_v72ty_824",
  "hidden": "awsui_hidden_1q84n_v72ty_843",
  "header-replacement": "awsui_header-replacement_1q84n_v72ty_848",
  "content-replacement": "awsui_content-replacement_1q84n_v72ty_853",
  "dismiss-button": "awsui_dismiss-button_1q84n_v72ty_170",
  "breakpoint-default": "awsui_breakpoint-default_1q84n_v72ty_872",
  "action-button": "awsui_action-button_1q84n_v72ty_169",
  "action-slot": "awsui_action-slot_1q84n_v72ty_882",
  "flash-type-success": "awsui_flash-type-success_1q84n_v72ty_886",
  "flash-type-error": "awsui_flash-type-error_1q84n_v72ty_890",
  "flash-type-info": "awsui_flash-type-info_1q84n_v72ty_894",
  "flash-type-in-progress": "awsui_flash-type-in-progress_1q84n_v72ty_895",
  "flash-type-warning": "awsui_flash-type-warning_1q84n_v72ty_899"
};
  