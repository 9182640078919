
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_l0dv0_4my96_141",
  "header": "awsui_header_l0dv0_4my96_178",
  "header-link": "awsui_header-link_l0dv0_4my96_191",
  "header-link--has-logo": "awsui_header-link--has-logo_l0dv0_4my96_201",
  "header-link-text": "awsui_header-link-text_l0dv0_4my96_201",
  "header-logo": "awsui_header-logo_l0dv0_4my96_206",
  "header-logo--stretched": "awsui_header-logo--stretched_l0dv0_4my96_212",
  "items-control": "awsui_items-control_l0dv0_4my96_217",
  "list-container": "awsui_list-container_l0dv0_4my96_221",
  "with-toolbar": "awsui_with-toolbar_l0dv0_4my96_229",
  "divider-header": "awsui_divider-header_l0dv0_4my96_229",
  "list": "awsui_list_l0dv0_4my96_221",
  "list-variant-root": "awsui_list-variant-root_l0dv0_4my96_242",
  "list-variant-root--first": "awsui_list-variant-root--first_l0dv0_4my96_249",
  "list-variant-expandable-link-group": "awsui_list-variant-expandable-link-group_l0dv0_4my96_253",
  "list-item": "awsui_list-item_l0dv0_4my96_257",
  "section": "awsui_section_l0dv0_4my96_268",
  "expandable-link-group": "awsui_expandable-link-group_l0dv0_4my96_269",
  "section--no-ident": "awsui_section--no-ident_l0dv0_4my96_272",
  "expandable-link-group--no-ident": "awsui_expandable-link-group--no-ident_l0dv0_4my96_273",
  "refresh": "awsui_refresh_l0dv0_4my96_281",
  "list-variant-section-group": "awsui_list-variant-section-group_l0dv0_4my96_292",
  "section-group": "awsui_section-group_l0dv0_4my96_299",
  "section-group-title": "awsui_section-group-title_l0dv0_4my96_310",
  "link": "awsui_link_l0dv0_4my96_314",
  "link-active": "awsui_link-active_l0dv0_4my96_323",
  "info": "awsui_info_l0dv0_4my96_360",
  "external-icon": "awsui_external-icon_l0dv0_4my96_364",
  "divider": "awsui_divider_l0dv0_4my96_229",
  "divider-default": "awsui_divider-default_l0dv0_4my96_373"
};
  