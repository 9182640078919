import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useIsMobile } from '../hooks/useIsMobile'
import { AppContext } from '../App'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadPopover } from '../common/RadPopover'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { EntityModal } from '../shared/EntityModal'
import { formatDate, isNullOrWhitespace } from '../common/utilities'

export function RecordActivity () {
  const { loadingCount } = useContext(AppContext)
  const isMobile = useIsMobile()
  const PAGE_LENGTH = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedEntity, setSelectedEntity] = useState()
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [fromDate, setFromDate] = useState(searchParams.get('from') ?? '')
  const [toDate, setToDate] = useState(searchParams.get('to') ?? '')
  const [type, setType] = useState(searchParams.get('type') ?? '')
  const [role, setRole] = useState(searchParams.get('role') ?? '')
  const [sortOrder, setSortOrder] = useState(searchParams.get('sort') ?? 'total')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [exporting, setExporting] = useState(false)
  const { data, count } = useGet([
    '/api/report/record-activity',
    `?search=${searchText}`,
    `&fromDate=${fromDate}`,
    `&toDate=${toDate}`,
    `&type=${type}`,
    `&role=${role}`,
    `&limit=${PAGE_LENGTH}` +
    `&offset=${(currentPageIndex - 1) * PAGE_LENGTH}`,
    `&sortOrder=${sortOrder}`
  ].join(''))
  const { data: exportData } = useGet(
    exporting
      ? [
          '/api/report/record-activity',
          `?search=${searchText}`,
          `&fromDate=${fromDate}`,
          `&toDate=${toDate}`,
          `&type=${type}`,
          `&role=${role}`,
          '&limit=5000' +
          `&sortOrder=${sortOrder}`
        ].join('')
      : null
  )
  const { data: roleOptions } = useGet('/api/option/role?all=true')

  useEffect(() => {
    if (exportData != null) {
      exportToExcel()
      setExporting(false)
    }
  }, [exportData])

  function exportToExcel () {
    const rows = exportData
      .map(x => [
        x.entity_name,
        x.entity_type.charAt(0).toUpperCase() + x.entity_type.slice(1),
        x.total
      ])
    rows.unshift(['Name', 'Type', 'Views'])

    const worksheet = XLSX.utils.aoa_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Record Activity')

    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const date = now.getDate().toString().padStart(2, '0')
    const fileName = `record-activity_${year}_${month}_${date}.xlsx`
    XLSX.writeFile(workbook, fileName, { compression: true })
  }

  if (
    data != null && roleOptions != null
  ) {
    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              {
                text: (
                  <div className='breadcrumb-container'>
                    <span style={{ fontWeight: '400' }}>Reports</span>
                    <div className='breadcrumb-carat' />
                    <span>Record Activity</span>
                  </div>
                )
              }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentHeader={
          <RadHeader
            actions={
              <RadButton onClick={() => setExporting(true)}>Export</RadButton>
            }
            counter={'(' + count + ')'}
            variant='h1'
          >
            Record Activity
          </RadHeader>
        }
        content={
          <div>
            <RadTable
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => (
                    isMobile
                      ? <RadLink href={`/${e.entity_type}/${e.entity_id}`}>{e.entity_name}</RadLink>
                      : (
                        <RadButton
                          onClick={() => setSelectedEntity({ type: e.entity_type, id: e.entity_id })}
                          variant='inline-link'
                        >
                          {e.entity_name}
                        </RadButton>
                        )
                  )
                },
                {
                  id: 'type',
                  header: 'Type',
                  cell: e => e.entity_type.charAt(0).toUpperCase() + e.entity_type.slice(1)
                },
                // {
                //   id: 'role',
                //   header: 'User Type',
                //   cell: e => e.role
                // },
                {
                  id: 'total',
                  header: 'Views',
                  cell: e => e.total
                }
              ]}
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No matches found.
                </RadBox>
            }
              filter={
                <RadSpaceBetween size='xs'>
                  <RadGrid
                    gridDefinition={[
                      { colspan: { default: 12, xs: 6 } },
                      { colspan: { default: 12, xs: 6 } }
                    ]}
                  >
                    <RadTextFilter
                      filteringPlaceholder='Search'
                      filteringAriaLabel='Search'
                      filteringText={filteringText}
                      onChange={({ detail }) => setFilteringText(detail.filteringText)}
                      onDelayedChange={({ detail }) => {
                        setSearchText(detail.filteringText)
                        setCurrentPageIndex(1)
                        searchParams.delete('page')
                        if (!isNullOrWhitespace(detail.filteringText)) {
                          searchParams.set('search', detail.filteringText)
                        } else {
                          searchParams.delete('search')
                        }
                        setSearchParams(searchParams)
                      }}
                    />
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <SortPopover
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        setCurrentPageIndex={setCurrentPageIndex}
                        loadingCount={loadingCount}
                      />
                      <FilterPopover
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setCurrentPageIndex={setCurrentPageIndex}
                        loadingCount={loadingCount}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        type={type}
                        setType={setType}
                        roleOptions={roleOptions}
                        role={role}
                        setRole={setRole}
                      />
                    </RadSpaceBetween>
                  </RadGrid>
                  <RadBox color='text-status-inactive'>
                    {fromDate === '' && toDate === '' && 'All time '}
                    {fromDate !== '' && toDate !== '' && `${formatDate(fromDate)} to ${formatDate(toDate)} `}
                    {fromDate !== '' && toDate === '' && `After ${formatDate(fromDate)} `}
                    {fromDate === '' && toDate !== '' && `Before ${formatDate(toDate)} `}
                    {type !== '' && `for ${type.charAt(0).toUpperCase() + type.slice(1)} `}
                    {role !== '' && `for ${roleOptions.find(x => x.value === role)?.label} `}
                    {`sorted by ${sortOrder}`}
                  </RadBox>
                </RadSpaceBetween>
            }
              pagination={
                <RadBox padding={{ bottom: 'xxl' }}>
                  <RadPagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={Math.ceil(Number(data?.[0]?.total ?? 0) / PAGE_LENGTH)}
                    onChange={({ detail }) => {
                      if (detail.currentPageIndex === 1) {
                        searchParams.delete('page')
                      } else {
                        searchParams.set('page', detail.currentPageIndex)
                      }
                      setSearchParams(searchParams)
                      setCurrentPageIndex(detail.currentPageIndex)
                    }}
                    ariaLabels={{
                      nextPageLabel: 'Next page',
                      previousPageLabel: 'Previous page',
                      pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                    }}
                  />
                </RadBox>
            }
              items={data}
              variant='embedded'
            />
            <EntityModal
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              closeModal={() => setSelectedEntity(null)}
            />
          </div>
        }
      />
    )
  }
}

function SortPopover ({ searchParams, setSearchParams, sortOrder, setSortOrder, setCurrentPageIndex, loadingCount }) {
  return (
    <RadPopover
      position='bottom'
      size='small'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Sort
          </RadHeader>
          <RadRadioGroup
            onChange={({ detail }) => {
              setSortOrder(detail.value)
              if (detail.value === 'count') {
                searchParams.delete('sort')
              } else {
                searchParams.set('sort', detail.value)
              }
              searchParams.delete('page')
              setSearchParams(searchParams)
              setCurrentPageIndex(1)
            }}
            items={[
              { value: 'name', label: 'Name', disabled: loadingCount > 0 },
              { value: 'total', label: 'Total', disabled: loadingCount > 0 }
            ]}
            value={sortOrder}
          />
        </RadSpaceBetween>
      }
    >
      <RadButton className='sort' />
    </RadPopover>
  )
}

function FilterPopover ({
  searchParams,
  setSearchParams,
  setCurrentPageIndex,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  type,
  setType,
  roleOptions,
  role,
  setRole,
  loadingCount
}) {
  const typeOptions = [
    { value: '', label: 'All' },
    { value: 'organization', label: 'Organization' },
    { value: 'collaborative', label: 'Collaborative' },
    { value: 'project', label: 'Project' },
    { value: 'resource', label: 'Resource' }
  ]

  return (
    <RadPopover
      position='bottom'
      size='large'
      triggerType='custom'
      content={
        <RadSpaceBetween size='xs'>
          <RadHeader
            variant='h2'
          >
            Filter
          </RadHeader>
          <RadFormField label='From'>
            <RadInput
              onChange={({ detail }) => {
                if (detail.value === '') {
                  searchParams.delete('from')
                } else {
                  searchParams.set('from', detail.value)
                }
                setFromDate(detail.value)
                searchParams.delete('page')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
              }}
              type='date'
              value={fromDate ?? ''}
            />
          </RadFormField>
          <RadFormField label='To'>
            <RadInput
              onChange={({ detail }) => {
                if (detail.value === '') {
                  searchParams.delete('to')
                } else {
                  searchParams.set('to', detail.value)
                }
                setToDate(detail.value)
                searchParams.delete('page')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
              }}
              type='date'
              value={toDate ?? ''}
            />
          </RadFormField>
          <RadFormField label='Record Type'>
            <RadSelect
              filteringType='auto'
              selectedOption={typeOptions.find(x => x.value === type)}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('type')
                } else {
                  searchParams.set('type', detail.selectedOption.value)
                }
                setType(detail.selectedOption.value)
                searchParams.delete('page')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
              }}
              options={typeOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a type'
              empty='No matches found'
            />
          </RadFormField>
          <RadFormField label='User Type'>
            <RadSelect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOption={roleOptions.find(x => x.value === role) ?? roleOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('role')
                } else {
                  searchParams.set('role', detail.selectedOption.value)
                }
                setRole(detail.selectedOption.value)
                setCurrentPageIndex(1)
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={roleOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a user type'
              empty='No matches found'
            />
          </RadFormField>
          <RadBox float='right' padding={{ top: 'l', bottom: 'xxs' }}>
            <RadButton
              onClick={() => {
                searchParams.delete('page')
                searchParams.delete('from')
                searchParams.delete('to')
                searchParams.delete('type')
                searchParams.delete('role')
                setSearchParams(searchParams)
                setCurrentPageIndex(1)
                setFromDate('')
                setToDate('')
                setType('')
                setRole('')
              }}
            >
              Clear All Filters
            </RadButton>
          </RadBox>
        </RadSpaceBetween>
      }
    >
      <RadButton className='filter' />
    </RadPopover>
  )
}
