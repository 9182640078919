import { RadSpaceBetween } from './RadSpaceBetween'
import { RadBadge } from './RadBadge'

export function RadBadgeGroup ({ parentKey, items, mapFunc, ...rest }) {
  const badges = items
    .map(mapFunc)
    .map((x) => <RadBadge key={`${parentKey}-${x.key}`}>{x.name}</RadBadge>)

  return (
    <RadSpaceBetween direction='horizontal' size='xxs'>
      {badges.length > 0 ? badges : <div>-</div>}
    </RadSpaceBetween>
  )
}
