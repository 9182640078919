import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useIsMobile } from '../hooks/useIsMobile'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { stateList } from '../common/stateList'

export function OrganizationEditContent ({ organization, formValues, setFormValues, modal = false }) {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { data: typeOptions } = useGet('/api/option/type?entity=organization')
  const { data: sectorOptions } = useGet('/api/option/sector')
  // const { data: software } = useGet('/api/software')
  const { data: titleOptions } = useGet('/api/option/type?entity=title')
  const { data: targetPopulations } = useGet('/api/target-population?limit=1000')
  const { data: tags } = useGet('/api/tag')
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { users: [], sectors: [], people: [], contacts: [], projects: [], services: [], software: [], targetAreas: [], targetPopulations: [], tags: [] }
    setFormValues(organization ?? defaultFormValues)
  }, [organization])

  const create = usePost('/api/organization', formValues, (resp) => { navigate(`/organization-collaborative?organizationId=${resp.id}`) })
  const update = usePut(`/api/organization/${organization?.id}`, formValues, (resp) => { navigate(`/organization/${organization?.id}`) })

  async function saveChanges () {
    if (organization != null) { update() } else { create() }
  }

  async function cancel () {
    if (organization != null) {
      navigate(`/organization/${organization?.id}`)
    } else {
      navigate('/organization-collaborative')
    }
  }

  if (
    formValues != null &&
    typeOptions != null &&
    titleOptions != null &&
    sectorOptions != null &&
    // software != null &&
    targetPopulations != null &&
    tags != null
  ) {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <RadForm
          actions={
            !modal
              ? (
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>)
              : null
          }
        >
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadFormField label='Name' field='name' required>
                  <RadInput
                    placeholder='Enter name'
                    ariaRequired
                    value={formValues.name}
                    onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                    disabled={formValues.isProtected}
                  />
                </RadFormField>
                <RadFormField label='Type' field='typeId' required>
                  <RadSelect
                    selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                    onChange={({ detail }) => {
                      if (detail.selectedOption.value !== '') {
                        setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                      } else {
                        setFormValues({ ...formValues, typeId: null })
                      }
                    }}
                    options={typeOptions}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                </RadFormField>
                <RadFormField label='Description' field='description' required>
                  <RadTextarea
                    placeholder='Enter description'
                    value={formValues.description}
                    onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                  />
                </RadFormField>
                <RadFormField label='Website' field='website'>
                  <RadInput
                    type='url'
                    inputMode='url'
                    placeholder='Enter website url'
                    value={formValues.website}
                    onChange={({ detail }) => setFormValues({ ...formValues, website: detail.value })}
                  />
                </RadFormField>
              </RadSpaceBetween>
            </RadContainer>

            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Address
                </RadHeader>
              }
            >
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xxs: 6 } },
                  { colspan: { default: 12, xxs: 6 } }
                ]}
              >
                <RadFormField label='Address' field='line1'>
                  <RadInput
                    placeholder='Enter line 1'
                    value={formValues.address?.line1}
                    onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
                  />
                </RadFormField>
                <RadFormField label={isMobile ? null : '.'} field='line2'>
                  <RadInput
                    placeholder='Enter line 2'
                    value={formValues.address?.line2}
                    onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
                  />
                </RadFormField>
              </RadGrid>
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xxs: 6 } },
                  { colspan: { default: 6, xxs: 3 } },
                  { colspan: { default: 6, xxs: 3 } }
                ]}
              >
                <RadFormField field='city'>
                  <RadInput
                    placeholder='Enter city'
                    value={formValues.address?.city}
                    onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
                  />
                </RadFormField>
                <RadFormField field='state'>
                  <RadSelect
                    filteringType='auto'
                    placeholder='Choose a state'
                    options={stateListOptions}
                    selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
                    onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
                  />
                </RadFormField>
                <RadFormField field='zip'>
                  <RadInput
                    placeholder='Enter ZIP code'
                    inputMode='numeric'
                    value={formValues.address?.zip}
                    onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
                  />
                </RadFormField>
              </RadGrid>
            </RadContainer>

            <RadContainer
              field='sectors'
              header={
                <RadHeader variant='h2'>Sectors *</RadHeader>
              }
            >
              <RadAttributeEditor
                onAddButtonClick={() => {
                  const sectors = formValues.sectors
                  sectors.push({ uuid: uuidv4() })
                  setFormValues({ ...formValues, sectors })
                }}
                onRemoveButtonClick={({
                  detail: { itemIndex }
                }) => {
                  const sectors = [...formValues.sectors]
                  sectors.splice(itemIndex, 1)
                  setFormValues({ ...formValues, sectors })
                }}
                items={formValues.sectors}
                addButtonText='Add new sector'
                definition={[
                  {
                    label: 'Sector *',
                    control: item => {
                      const filteredOptions = sectorOptions.filter((x) => !formValues.sectors.map((y) => y.sectorId?.toString()).includes(x.value))
                      return (
                        <RadFormField field={`sector.${item.id ?? item.uuid}.sectorId`}>
                          <RadSelect
                            filteringType='auto'
                            selectedOption={sectorOptions.find(x => x.value === item.sectorId?.toString())}
                            onChange={({ detail }) => {
                              const sectors = formValues.sectors
                              const sector = formValues.sectors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                              sector.sectorId = parseInt(detail.selectedOption.value)
                              sector.name = detail.selectedOption.label
                              sector.lastName = detail.selectedOption.label.split(' ')[1]
                              setFormValues({ ...formValues, sectors })
                            }}
                            options={filteredOptions}
                            enteredTextLabel={value => value}
                            selectedAriaLabel='Selected'
                            placeholder='Choose a sector'
                            empty='No matches found'
                          />
                        </RadFormField>
                      )
                    }
                  }
                ]}
                removeButtonText='Remove'
                empty='No sectors added to this organization.'
              />
            </RadContainer>

            {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Members</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const people = formValues.people
                      people.push({ uuid: uuidv4(), primaryEmployer: false })
                      setFormValues({ ...formValues, people })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const people = [...formValues.people]
                      people.splice(itemIndex, 1)
                      setFormValues({ ...formValues, people })
                    }}
                    items={formValues.people}
                    addButtonText='Add new member'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const filteredOptions = personOptions.filter((x) => !formValues.people.map((y) => y.personId?.toString()).includes(x.value))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.personId`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={personOptions.find(x => x.value === item.personId?.toString())}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  item.personId = parseInt(detail.selectedOption.value)
                                  setFormValues({ ...formValues, people })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a person'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Title',
                        control: item => {
                          const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.title`}>
                              <RadInput
                                placeholder='Enter title'
                                ariaRequired
                                value={item.title}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  person.title = detail.value
                                  setFormValues({ ...formValues, people })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Primary Employer',
                        control: item => {
                          const person = formValues.people.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`people.${item.id ?? item.uuid}.primaryEmployer`}>
                              <RadCheckbox
                                checked={person.primaryEmployer}
                                onChange={({ detail }) => {
                                  const people = formValues.people
                                  person.primaryEmployer = detail.checked
                                  setFormValues({ ...formValues, people })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove'
                    empty='No people added to this organization.'
                  />
                </RadContainer> */}

            <Contacts formValues={formValues} setFormValues={setFormValues} />

            <Projects formValues={formValues} setFormValues={setFormValues} />

            <Services formValues={formValues} setFormValues={setFormValues} />

            {/* <RadContainer
                  header={
                    <RadHeader variant='h2'>Software</RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const software = formValues.software
                      software.push({ uuid: uuidv4(), software: {} })
                      setFormValues({ ...formValues, software })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const software = [...formValues.software]
                      software.splice(itemIndex, 1)
                      setFormValues({ ...formValues, software })
                    }}
                    items={formValues.software}
                    addButtonText='Add new software'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          const softwareOptions = software.map((x) => { return { value: x.id.toString(), label: x.name } })
                          const filteredOptions = (softwareOptions ?? []).filter((x) => !formValues.software.map((y) => y.name).includes(x.value))
                          return (
                            <RadFormField field={`software.${item.id ?? item.uuid}.name`}>
                              <RadSelect
                                filteringType='auto'
                                selectedOption={softwareOptions.find(x => x.value === item.softwareId?.toString())}
                                onChange={({ detail }) => {
                                  const software = formValues.software
                                  const thisSoftware = formValues.software.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                  thisSoftware.id = null
                                  thisSoftware.softwareId = parseInt(detail.selectedOption.value)
                                  thisSoftware.software.name = detail.selectedOption.label
                                  setFormValues({ ...formValues, software })
                                }}
                                options={filteredOptions}
                                enteredTextLabel={value => value}
                                selectedAriaLabel='Selected'
                                placeholder='Choose a software'
                                empty='No matches found'
                              />
                            </RadFormField>
                          )
                        }
                      }

                    ]}
                    removeButtonText='Remove'
                    empty='No software added to this organization.'
                  />
                </RadContainer> */}

            <PriorityAreas formValues={formValues} setFormValues={setFormValues} />

            {/* Target Populations */}
            <RadContainer
              field='targetPopulations'
              header={
                <RadHeader variant='h2'>Priority Populations *</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const targetPopulations = formValues.targetPopulations
                    targetPopulations.push({ uuid: uuidv4(), targetPopulation: {} })
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const targetPopulations = [...formValues.targetPopulations]
                    targetPopulations.splice(itemIndex, 1)
                    setFormValues({ ...formValues, targetPopulations })
                  }}
                  items={formValues.targetPopulations}
                  addButtonText='Add new priority population'
                  definition={[
                    {
                      label: 'Name',
                      control: item => {
                        const targetPopulationOptions = targetPopulations.map((x) => { return { value: x.id.toString(), label: x.name } })
                        const filteredOptions = (targetPopulationOptions ?? []).filter((x) => !formValues.targetPopulations.map((y) => y.name).includes(x.value))
                        return (
                          <RadFormField field={`targetPopulation.${item.id || item.uuid}.targetPopulationId`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.targetPopulation.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter priority population'
                              onChange={({ detail }) => {
                                const targetPopulations = formValues.targetPopulations
                                // const targetPopulation = formValues.targetPopulations.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                const selectedOption = targetPopulationOptions.find((x) => x.value === detail.value)
                                console.log(selectedOption)
                                // targetPopulation.id = null
                                // if (selectedOption == null) {
                                //   targetPopulation.targetPopulationId = null
                                //   targetPopulation.targetPopulation.name = detail.value
                                // } else {
                                //   targetPopulation.targetPopulationId = parseInt(selectedOption.value)
                                //   targetPopulation.targetPopulation.name = selectedOption.label
                                // }
                                if (selectedOption == null) {
                                  item.targetPopulationId = null
                                  item.targetPopulation.name = detail.value
                                } else {
                                  item.targetPopulationId = parseInt(selectedOption.value)
                                  item.targetPopulation.name = selectedOption.label
                                }
                                setFormValues({ ...formValues, targetPopulations })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No priority populations added to this organization.'
                />
              </RadSpaceBetween>
            </RadContainer>

            {/* Tags */}
            <RadContainer
              field='tags'
              header={
                <RadHeader variant='h2'>Tags</RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadAttributeEditor
                  onAddButtonClick={() => {
                    const tags = formValues.tags
                    tags.push({ uuid: uuidv4(), tag: {} })
                    setFormValues({ ...formValues, tags })
                  }}
                  onRemoveButtonClick={({
                    detail: { itemIndex }
                  }) => {
                    const tags = [...formValues.tags]
                    tags.splice(itemIndex, 1)
                    setFormValues({ ...formValues, tags })
                  }}
                  items={formValues.tags}
                  addButtonText='Add new tag'
                  definition={[
                    {
                      label: 'Name',
                      control: item => {
                        const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                        const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.tag.name).includes(x.value))
                        return (
                          <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                            <RadAutosuggest
                              options={filteredOptions}
                              value={item.tag.name ?? ''}
                              enteredTextLabel={value => `Use: "${value}"`}
                              placeholder='Enter tag'
                              onChange={({ detail }) => {
                                const tags = formValues.tags
                                const tag = formValues.tags.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                const id = tagOptions.find((x) => x.value === detail.value)?.id
                                tag.id = null
                                tag.tag.id = id
                                tag.tag.name = detail.value
                                setFormValues({ ...formValues, tags })
                              }}
                            />
                          </RadFormField>
                        )
                      }
                    }
                  ]}
                  removeButtonText='Remove'
                  empty='No tags added to this organization.'
                />
              </RadSpaceBetween>
            </RadContainer>
          </RadSpaceBetween>
        </RadForm>
      </form>
    )
  }
}

function Contacts ({ formValues, setFormValues }) {
  return (
    <RadContainer
      field='contacts'
      header={
        <RadHeader variant='h2'>Contacts *</RadHeader>
      }
    >
      <RadAttributeEditor
        onAddButtonClick={() => {
          const contacts = formValues.contacts
          contacts.push({ uuid: uuidv4() })
          setFormValues({ ...formValues, contacts })
        }}
        onRemoveButtonClick={({
          detail: { itemIndex }
        }) => {
          const contacts = [...formValues.contacts]
          contacts.splice(itemIndex, 1)
          setFormValues({ ...formValues, contacts })
        }}
        items={formValues.contacts}
        addButtonText='Add new contact'
        definition={[
          {
            label: 'Name *',
            control: item => {
              return (
                <RadFormField field={`contact.${item.id ?? item.uuid}.name`}>
                  <RadInput
                    onChange={({ detail }) => {
                      const contacts = formValues.contacts
                      item.name = detail.value
                      setFormValues({ ...formValues, contacts })
                    }}
                    placeholder='Enter name'
                    ariaRequired
                    value={item.name}
                    disabled={formValues.isProtected}
                  />
                </RadFormField>
              )
            }
          }
        ]}
        removeButtonText='Remove'
        empty='No contacts added to this organization.'
      />
    </RadContainer>
  )
}

function PriorityAreas ({ formValues, setFormValues }) {
  const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const { data: targetAreaOptions } = useGet(`/api/option/target-area?search=${targetAreaFilteringText}`)

  if (
    targetAreaOptions != null
  ) {
    return (
      <RadContainer
        field='targetAreas'
        header={
          <RadHeader
            variant='h2'
            description='Priority areas are states, counties, cities or zip codes.'
          >
            Priority Areas *
          </RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const targetAreas = formValues.targetAreas
            targetAreas.push({ uuid: uuidv4(), targetArea: {} })
            setFormValues({ ...formValues, targetAreas })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const targetAreas = [...formValues.targetAreas]
            targetAreas.splice(itemIndex, 1)
            setFormValues({ ...formValues, targetAreas })
          }}
          items={formValues.targetAreas}
          addButtonText='Add new priority area'
          definition={[
            {
              label: 'Name *',
              control: item => {
                const targetAreas = formValues.targetAreas
                const targetArea = formValues.targetAreas.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                const filteredOptions = (targetAreaOptions ?? []).filter((x) => formValues.targetAreas.find((y) => y.targetArea.name === x.label) == null)
                return (
                  <RadFormField field={`targetArea.${item.id ?? item.uuid}.targetAreaId`}>
                    <RadSelect
                      selectedOption={item.targetAreaId != null ? { value: item.targetAreaId, label: item.targetArea.name } : null}
                      onChange={({ detail }) => {
                        targetArea.targetAreaId = parseInt(detail.selectedOption.value)
                        targetArea.targetArea.name = detail.selectedOption.label
                        setFormValues({ ...formValues, targetAreas })
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a priority area'
                      filteringType='manual'
                      onLoadItems={({ detail }) => {
                        setTargetAreaFilteringText(detail.filteringText)
                      }}
                      empty={targetAreaFilteringText !== '' ? 'No matches found' : null}
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No priority areas added to this organization.'
        />
      </RadContainer>

    )
  }
}

function Projects ({ formValues, setFormValues }) {
  const [filteringText, setFilteringText] = useState('')
  const { data: projectOptions } = useGet(`/api/option/project?search=${filteringText}`)

  if (projectOptions != null) {
    return (
      <RadContainer
        field='projects'
        header={
          <RadHeader variant='h2'>Projects</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const projects = formValues.projects
            projects.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, projects })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const projects = [...formValues.projects]
            projects.splice(itemIndex, 1)
            setFormValues({ ...formValues, projects })
          }}
          items={formValues.projects}
          addButtonText='Add new project'
          definition={[
            {
              label: 'Project *',
              control: item => {
                const filteredOptions = projectOptions.filter((x) => !formValues.projects.map((y) => y.projectId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`projects.${item.id ?? item.uuid}.projectId`}>
                    <RadSelect
                      filteringType='manual'
                      selectedOption={projectOptions.find(x => x.value === item.projectId?.toString())}
                      onChange={({ detail }) => {
                        const projects = formValues.projects
                        const project = formValues.projects.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        project.projectId = parseInt(detail.selectedOption.value)
                        project.name = detail.selectedOption.label
                        setFormValues({ ...formValues, projects })
                      }}
                      onLoadItems={({ detail }) => {
                        setFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a project'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No projects added to this organization.'
        />
      </RadContainer>
    )
  }
}

function Services ({ formValues, setFormValues }) {
  const [serviceFilteringText, setServiceFilteringText] = useState('')
  const { data: serviceOptions } = useGet(`/api/option/service?search=${serviceFilteringText}&limit=1000`)

  if (serviceOptions != null) {
    return (
      <RadContainer
        field='services'
        header={
          <RadHeader variant='h2'>Services</RadHeader>
        }
      >
        <RadAttributeEditor
          onAddButtonClick={() => {
            const services = formValues.services
            services.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, services })
          }}
          onRemoveButtonClick={({
            detail: { itemIndex }
          }) => {
            const services = [...formValues.services]
            services.splice(itemIndex, 1)
            setFormValues({ ...formValues, services })
          }}
          items={formValues.services}
          addButtonText='Add new service'
          definition={[
            {
              label: 'Service *',
              control: item => {
                const filteredOptions = serviceOptions.filter((x) => !formValues.services.map((y) => y.serviceId?.toString()).includes(x.value))
                return (
                  <RadFormField field={`services.${item.id ?? item.uuid}.serviceId`}>
                    <RadSelect
                      filteringType='manual'
                      selectedOption={serviceOptions.find(x => x.value === item.serviceId?.toString())}
                      onChange={({ detail }) => {
                        const services = formValues.services
                        const service = formValues.services.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                        service.serviceId = parseInt(detail.selectedOption.value)
                        service.name = detail.selectedOption.label
                        setFormValues({ ...formValues, services })
                      }}
                      onLoadItems={({ detail }) => {
                        setServiceFilteringText(detail.filteringText)
                      }}
                      options={filteredOptions}
                      enteredTextLabel={value => value}
                      selectedAriaLabel='Selected'
                      placeholder='Choose a service'
                      empty='No matches found'
                    />
                  </RadFormField>
                )
              }
            }
          ]}
          removeButtonText='Remove'
          empty='No services added to this organization.'
        />
      </RadContainer>
    )
  }
}
