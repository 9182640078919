import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { ResourceDetailContent } from './ResourceDetailContent'

export function ResourceDetail () {
  const navigate = useNavigate()
  const { resourceId } = useParams()
  const { data: resource } = useGet(`/api/resource/${resourceId}`, true)
  const { data: currentUser } = useGet('/api/user/current')
  const confirmDelete = useConfirm('Delete resource?', 'Delete resource permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/resource/${resourceId}`, () => { navigate('/resource') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (resource != null && currentUser != null) {
    const currentUserPermissions = currentUser?.roles?.reduce((acc, x) => acc.concat(x.permissions.map(y => y.name.toLowerCase())), []) ?? []
    const currentUserIsAdmin = currentUser?.roles?.some(x => x.name === 'Admin') ?? false

    return (
      <RadAppLayout
        name={resource.title}
        notifications={resource.summaryStatus != null ? [{ type: 'info', content: resource.summaryStatus }] : []}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                {(currentUserIsAdmin || currentUserPermissions.includes('resource edit')) && <RadButton onClick={edit}>Edit</RadButton>}
                {(currentUserIsAdmin || currentUserPermissions.includes('resource delete')) && <RadButton onClick={confirmDelete} disabled={resource.isProtected}>Delete</RadButton>}
              </RadSpaceBetween>
            }
            description={resource.summary}
          >
            {resource.title}
          </RadHeader>
        }
        content={
          <RadContainer
            header={
              <RadHeader variant='h2'>
                Details
              </RadHeader>
            }
          >
            <ResourceDetailContent resource={resource} />
          </RadContainer>
        }
      />
    )
  }
}
