import React from 'react'
import './SignIn.scss'

export function SignIn () {
  return (
    <div className='sign-in'>
      <div className='left' />
      <div className='right'>
        <h2>Get Started</h2>
        Sign in or Sign up using Google or your email.
      </div>
    </div>
  )
}
