
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_2qdw9_vwol1_177",
  "refresh": "awsui_refresh_2qdw9_vwol1_216",
  "root-no-actions": "awsui_root-no-actions_2qdw9_vwol1_216",
  "root-variant-h2": "awsui_root-variant-h2_2qdw9_vwol1_219",
  "root-variant-h3": "awsui_root-variant-h3_2qdw9_vwol1_219",
  "root-has-description": "awsui_root-has-description_2qdw9_vwol1_219",
  "root-variant-h1": "awsui_root-variant-h1_2qdw9_vwol1_228",
  "main": "awsui_main_2qdw9_vwol1_238",
  "no-wrap": "awsui_no-wrap_2qdw9_vwol1_248",
  "main-variant-h1": "awsui_main-variant-h1_2qdw9_vwol1_254",
  "actions": "awsui_actions_2qdw9_vwol1_262",
  "actions-centered": "awsui_actions-centered_2qdw9_vwol1_267",
  "actions-variant-h1": "awsui_actions-variant-h1_2qdw9_vwol1_270",
  "actions-variant-h2": "awsui_actions-variant-h2_2qdw9_vwol1_274",
  "actions-variant-h3": "awsui_actions-variant-h3_2qdw9_vwol1_278",
  "title": "awsui_title_2qdw9_vwol1_294",
  "title-variant-h1": "awsui_title-variant-h1_2qdw9_vwol1_299",
  "title-variant-h2": "awsui_title-variant-h2_2qdw9_vwol1_306",
  "title-variant-h3": "awsui_title-variant-h3_2qdw9_vwol1_312",
  "virtual-space": "awsui_virtual-space_2qdw9_vwol1_333",
  "info": "awsui_info_2qdw9_vwol1_339",
  "description": "awsui_description_2qdw9_vwol1_343",
  "description-variant-h1": "awsui_description-variant-h1_2qdw9_vwol1_350",
  "description-variant-h2": "awsui_description-variant-h2_2qdw9_vwol1_354",
  "description-variant-h3": "awsui_description-variant-h3_2qdw9_vwol1_361",
  "heading": "awsui_heading_2qdw9_vwol1_370",
  "heading-variant-h1": "awsui_heading-variant-h1_2qdw9_vwol1_381",
  "heading-variant-h2": "awsui_heading-variant-h2_2qdw9_vwol1_386",
  "heading-variant-h3": "awsui_heading-variant-h3_2qdw9_vwol1_391",
  "heading-text": "awsui_heading-text_2qdw9_vwol1_397",
  "heading-text-variant-h1": "awsui_heading-text-variant-h1_2qdw9_vwol1_400",
  "heading-text-variant-h2": "awsui_heading-text-variant-h2_2qdw9_vwol1_408",
  "heading-text-variant-h3": "awsui_heading-text-variant-h3_2qdw9_vwol1_416",
  "counter": "awsui_counter_2qdw9_vwol1_425"
};
  