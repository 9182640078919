import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadForm } from '../common/RadForm'
import { RadInput } from '../common/RadInput'
import { RadFormField } from '../common/RadFormField'
import { RadButton } from '../common/RadButton'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'

export function TargetPopulationEdit () {
  const navigate = useNavigate()
  const { targetPopulationId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: targetPopulation } = useGet(targetPopulationId ? `/api/target-population/${targetPopulationId}` : null)

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(targetPopulation ?? defaultFormValues)
  }, [targetPopulation])

  const create = usePost('/api/target-population', formValues, (resp) => { navigate('/priority-population') })
  const update = usePut(`/api/target-population/${targetPopulationId}`, formValues, (resp) => { navigate('/priority-population') })

  async function saveChanges () {
    if (targetPopulationId != null) { update() } else { create() }
  }

  async function cancel () {
    navigate('/priority-population')
  }

  if (formValues != null) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Priority Population' : 'New Priority Population'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
