import React from 'react'
import { usePut } from '../hooks/usePut'

export function ResourceFeedback ({ resource }) {
  const feedback = resource?.feedback || 0
  const up = usePut(`/api/resource/${resource?.id}/feedback`, { value: 1 }, null, true, false)
  const down = usePut(`/api/resource/${resource?.id}/feedback`, { value: -1 }, null, true, false)
  const clear = usePut(`/api/resource/${resource?.id}/feedback`, { value: 0 }, null, true, false)

  function handleClick (value) {
    if (value === 1 && feedback < 1) return up
    if (value === -1 && feedback > -1) return down
    if (value === 1 && feedback === 1) return clear
    if (value === -1 && feedback === -1) return clear
  }

  return (
    <div className='feedback'>
      <div>Find what you were looking for?</div>
      <div>
        <button
          onClick={handleClick(1)}
          className={'thumbs-up' + (feedback === 1 ? '-active' : '')}
        />
        <button
          onClick={handleClick(-1)}
          className={'thumbs-down' + (feedback === -1 ? '-active' : '')}
        />
      </div>
    </div>
  )
}
