import React from 'react'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function CollaborativeDetailContent ({ collaborative, setSelectedEntity }) {
  if (collaborative != null) {
    return (
      <RadSpaceBetween size='l'>
        <RadColumnLayout columns={4} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Website
            </RadBox>
            <div>{collaborative.website != null ? <RadLink href={collaborative.website}>{collaborative.website}</RadLink> : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Goal
            </RadBox>
            <div>{collaborative.goal ?? '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Contacts
            </RadBox>
            <div>{collaborative.contacts?.length > 0
              ? collaborative.contacts.map((x) => <div key={x.id}>{x.name}</div>)
              : '-'}
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Organizations
            </RadBox>
            <div>{collaborative.organizations?.length > 0
              ? collaborative.organizations.map((x) =>
                <div key={x.id}>
                  {setSelectedEntity == null && <RadLink href={`/organization/${x.organization.id}`}>{x.organization?.name}</RadLink>}
                  {setSelectedEntity != null &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'organization', id: x.organization.id })}
                      className='font-weight-400'
                      variant='inline-link'
                    >
                      {x.organization?.name}
                    </RadButton>}
                </div>
              )
              : '-'}
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Projects
            </RadBox>
            <div>{collaborative.projects?.length > 0
              ? collaborative.projects.map((x) =>
                <div key={x.id}>
                  {setSelectedEntity == null && <RadLink href={`/project/${x.project.id}`}>{x.project?.name}</RadLink>}
                  {setSelectedEntity != null &&
                    <RadButton
                      onClick={() => setSelectedEntity({ type: 'project', id: x.project.id })}
                      className='font-weight-400'
                      variant='inline-link'
                    >
                      {x.project?.name}
                    </RadButton>}
                </div>
              )
              : '-'}
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Sectors
            </RadBox>
            <div>
              <RadBadgeGroup
                parentKey={collaborative.id}
                items={collaborative.sectors ?? []}
                mapFunc={x => ({ key: x.id, name: x.sector?.name })}
              />
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Tags
            </RadBox>
            <div>
              <RadBadgeGroup
                parentKey={collaborative.id}
                items={collaborative.tags}
                mapFunc={x => ({ key: x.id, name: x.tag?.name })}
              />
            </div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Priority Areas
            </RadBox>
            <div>{collaborative.targetAreas?.length > 0 ? collaborative.targetAreas.map((x) => <div key={x.id}>{x.targetArea?.name}</div>) : '-'}</div>
          </div>
        </RadColumnLayout>
      </RadSpaceBetween>
    )
  }
}
