import React, { useEffect, useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { AppContext } from '../App'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput2 } from '../common/RadInput2'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadPopover } from '../common/RadPopover'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadMultiselect } from '../common/RadMultiselect'
import { EntityModal } from '../shared/EntityModal'
import { formatDateTime, getShortText } from '../common/utilities'

export function OrganizationAndCollaborativeList () {
  const pageLength = 20
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { loadingCount } = useContext(AppContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedEntity, setSelectedEntity] = useState()
  const currentPageIndex = searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1
  const searchText = searchParams.get('search') ?? ''
  const sortOrder = searchParams.get('sort') ?? 'alphabetical'
  const sectorId = searchParams.get('sector') ?? ''
  const tagIds = searchParams.get('tags') ?? ''
  const organizationTypeId = searchParams.get('organization-type') ?? ''
  const priorityAreaId = searchParams.get('priority-area') ?? ''
  const priorityPopulationIds = searchParams.get('priority-populations') ?? ''

  const { data: organizationTypeOptions } = useGet('/api/option/type?entity=organization&all=true')
  const { data: sectorOptions } = useGet('/api/option/sector?all=true')
  const { data: tagOptions } = useGet('/api/option/tag?all=true')
  const [targetAreaFilteringText, setTargetAreaFilteringText] = useState('')
  const { data: targetAreaOptions } = useGet(
    targetAreaFilteringText !== ''
      ? `/api/option/target-area?search=${targetAreaFilteringText}&all=true`
      : `/api/option/target-area?search=${targetAreaFilteringText}&all=true&limit=0`
  )
  const [targetPopulationFilteringText, setTargetPopulationFilteringText] = useState('')
  const { data: targetPopulationOptions } = useGet(`/api/option/priority-population?search=${targetPopulationFilteringText}&limit=500`)
  const { data: priorityArea } = useGet(priorityAreaId != null && priorityAreaId !== '' ? `/api/target-area/${priorityAreaId}` : null)
  const { data: userInfo } = useGet('/api/user/current')

  const { data: organizationsAndCollaboratives, count } = useGet([
    '/api/organization-collaborative',
    `?search=${searchText}`,
    `&sectorId=${sectorId}`,
    `&sortOrder=${sortOrder}`,
    `&tagIds=${tagIds ?? ''}`,
    `&organizationTypeId=${organizationTypeId}`,
    `&priorityAreaId=${priorityAreaId}`,
    `&priorityPopulationIds=${priorityPopulationIds}`,
    `&limit=${pageLength}`,
    `&offset=${(currentPageIndex - 1) * pageLength}`
  ].join(''))
  const { data: tags } = useGet('/api/tag?limit=5000')

  useEffect(() => {
    if (searchParams.get('collaborativeId') != null) {
      setSelectedEntity({ type: 'collaborative', id: parseInt(searchParams.get('collaborativeId')) })
      searchParams.delete('collaborativeId')
      setSearchParams(searchParams)
    }
    if (searchParams.get('organizationId') != null) {
      setSelectedEntity({ type: 'organization', id: parseInt(searchParams.get('organizationId')) })
      searchParams.delete('organizationId')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  if (
    organizationsAndCollaboratives != null &&
    organizationTypeOptions != null &&
    sectorOptions != null &&
    tags != null &&
    tagOptions != null &&
    targetAreaOptions != null &&
    targetPopulationOptions != null &&
    userInfo != null
  ) {
    const canCreate = userInfo.roles.some((x) => x.name === 'Admin') ||
      userInfo.roles.some((x) => x.permissions.some((y) => y.name === 'Organization Create'))
    const publicUser = userInfo.roles.some((x) => x.name === 'Public User') && userInfo.roles.length === 1

    return (
      <RadAppLayout
        breadcrumbs={
          <RadBreadcrumbGroup
            items={[
              { text: 'Home', href: '/' },
              { text: 'Organizations & Collaboratives', href: '/organization-collaborative' }
            ]}
            ariaLabel='Breadcrumbs'
          />
        }
        contentType='dashboard'
        contentHeader={
          <RadHeader
            counter={'(' + count + ')'}
            variant='h1'
            actions={
              !publicUser &&
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton disabled={!canCreate} onClick={() => navigate(`/organization/create?redirectURL=${window.location.pathname}`)}>Create Organization</RadButton>
                  <RadButton disabled={!canCreate} onClick={() => navigate(`/collaborative/create?redirectURL=${window.location.pathname}`)}>Create Collaborative</RadButton>
                </RadSpaceBetween>
            }
          >
            Organizations & Collaboratives
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadGrid
              gridDefinition={[
                { colspan: { default: 12, xs: 6 } },
                { colspan: { default: 12, xs: 2 } },
                { colspan: { default: 12, xs: 4 } }
              ]}
            >
              <RadInput2
                initialValue={searchText}
                placeholder='Enter a search query to sort by relevance'
                type='search'
                onEnter={({ detail }) => {
                  if (detail.value !== '') {
                    searchParams.set('search', detail.value)
                  } else {
                    searchParams.delete('search')
                  }
                  if (detail.value === '') {
                    searchParams.delete('sort')
                  } else {
                    searchParams.set('sort', 'relevance')
                  }
                  searchParams.delete('page')
                  setSearchParams(searchParams)
                }}
              />
              <RadSpaceBetween direction='horizontal' size='s'>
                <SortPopover
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  sortOrder={sortOrder}
                  loadingCount={loadingCount}
                />
                <FilterPopover
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  tagIds={tagIds}
                  sectorId={sectorId}
                  organizationTypeId={organizationTypeId}
                  priorityAreaId={priorityAreaId}
                  priorityPopulationIds={priorityPopulationIds}
                  targetAreaFilteringText={targetAreaFilteringText}
                  setTargetAreaFilteringText={setTargetAreaFilteringText}
                  targetPopulationFilteringText={targetPopulationFilteringText}
                  setTargetPopulationFilteringText={setTargetPopulationFilteringText}
                  organizationTypeOptions={organizationTypeOptions}
                  sectorOptions={sectorOptions}
                  tagOptions={tagOptions}
                  targetAreaOptions={targetAreaOptions}
                  targetPopulationOptions={targetPopulationOptions}
                  priorityArea={priorityArea}
                  loadingCount={loadingCount}
                />
              </RadSpaceBetween>
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            </RadGrid>
            {
              (sectorId !== '' || tagIds !== '' || organizationTypeId !== '' || priorityAreaId !== '' || priorityPopulationIds !== '') &&
                <RadBox color='text-status-inactive'>
                  {'Filter(s): ' +
                  [
                    sectorId !== '' ? `Sector: ${sectorOptions.find(x => x.value === sectorId)?.label}` : null,
                    tagIds !== '' ? `Tags: ${tagOptions.filter(x => tagIds.split('-').includes(x.value)).map(x => x.label).join(', ')}` : null,
                    organizationTypeId !== '' ? 'Organization Type: ' + organizationTypeOptions.find(x => x.value === organizationTypeId)?.label : null,
                    priorityArea != null ? 'Priority Area: ' + priorityArea.name : null,
                    priorityPopulationIds !== ''
                      ? 'Organization Priority Populations: ' + targetPopulationOptions
                        .filter(x => priorityPopulationIds.split('-').includes(x.value)).map(x => x.label).join(', ')
                      : null
                  ]
                    .filter(x => x !== null)
                    .join(', ')}
                </RadBox>
            }
            <RadCards
              cardDefinition={{
                header: item => {
                  if (isMobile) {
                    return (
                      <RadLink className='font-weight-700' fontSize='heading-s' href={`${item.type}/${item.id}`}>
                        {item.name}
                      </RadLink>
                    )
                  } else {
                    return (
                      <RadButton
                        onClick={() => setSelectedEntity({ type: item.type, id: item.id })}
                        className='font-weight-700 font-size-16'
                        variant='inline-link'
                      >
                        {item.name}
                      </RadButton>
                    )
                  }
                },
                sections: [
                  {
                    id: 'type',
                    content: item =>
                      <RadBox variant='awsui-key-label'>
                        {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                      </RadBox>
                  },
                  {
                    id: 'description',
                    content: item => getShortText(item.description)
                  },
                  {
                    id: 'sectors',
                    header: 'Sectors',
                    content: item =>
                      <RadBadgeGroup
                        parentKey={item.id}
                        items={item.sectors ?? []}
                        mapFunc={x => ({ key: x.id, name: x.name })}
                      />
                  },
                  {
                    id: 'updatedAt',
                    header: 'Updated',
                    content: item => formatDateTime(item.updatedAt)
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={organizationsAndCollaboratives}
              variant='full-page'
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No records meet this search criteria.
                </RadBox>
              }
            />
            <EntityModal
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              closeModal={() => setSelectedEntity(null)}
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}

function SortPopover ({ sortOrder, loadingCount }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <RadPopover
      position='bottom'
      size='small'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Sort
          </RadHeader>
          <RadRadioGroup
            onChange={({ detail }) => {
              if (detail.value === 'alphabetical') {
                searchParams.delete('sort')
              } else {
                searchParams.set('sort', detail.value)
              }
              searchParams.delete('page')
              setSearchParams(searchParams)
            }}
            items={[
              { value: 'alphabetical', label: 'Alphabetical', disabled: loadingCount > 0 || searchParams.get('search') != null },
              { value: 'relevance', label: 'Relevance', disabled: loadingCount > 0 || searchParams.get('search') == null },
              { value: 'recent', label: 'Recently Updated', disabled: loadingCount > 0 || searchParams.get('search') != null }
            ]}
            value={sortOrder}
          />
        </RadSpaceBetween>
      }
    >
      <RadButton className='sort' />
    </RadPopover>
  )
}

function FilterPopover ({
  loadingCount,
  organizationTypeId,
  organizationTypeOptions,
  priorityArea,
  priorityAreaId,
  priorityPopulationIds,
  sectorId,
  sectorOptions,
  setTargetAreaFilteringText,
  setTargetPopulationFilteringText,
  tagIds,
  tagOptions,
  targetAreaFilteringText,
  targetAreaOptions,
  targetPopulationFilteringText,
  targetPopulationOptions
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <RadPopover
      position='bottom'
      size='large'
      triggerType='custom'
      content={
        <RadSpaceBetween size='l'>
          <RadHeader
            variant='h2'
          >
            Filter
          </RadHeader>
          <RadSpaceBetween size='xs'>
            <RadSelect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOption={sectorOptions.find(x => x.value === sectorId) ?? sectorOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('sector')
                } else {
                  searchParams.set('sector', detail.selectedOption.value)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={sectorOptions}
              enteredTextLabel={value => value}
              selectedAriaLabel='Selected'
              placeholder='Choose a record type'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0}
              filteringType='auto'
              selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('tags')
                } else {
                  searchParams.set('tags', ids)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={tagOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose tags'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0}
              filteringType='manual'
              selectedOption={priorityArea != null ? { value: priorityArea.id, label: priorityArea.name } : targetAreaOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('priority-area')
                } else {
                  searchParams.set('priority-area', detail.selectedOption.value)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetAreaFilteringText(detail.filteringText)
              }}
              options={targetAreaOptions}
              filteringPlaceholder='Enter zip or location name'
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
            <RadMultiselect
              disabled={loadingCount > 0}
              filteringType='manual'
              selectedOptions={targetPopulationOptions.filter((x) => priorityPopulationIds.split('-').includes(x.value))}
              onChange={({ detail }) => {
                const ids = detail.selectedOptions.map((x) => x.value).join('-')
                if (ids === '') {
                  searchParams.delete('priority-populations')
                } else {
                  searchParams.set('priority-populations', ids)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              onLoadItems={({ detail }) => {
                setTargetPopulationFilteringText(detail.filteringText)
              }}
              options={targetPopulationOptions}
              selectedAriaLabel='Selected'
              deselectAriaLabel={e => `Remove ${e.label}`}
              placeholder='Choose priority populations'
              keepOpen={false}
            />
            <RadSelect
              disabled={loadingCount > 0}
              selectedOption={organizationTypeOptions.find(x => x.value === organizationTypeId) ?? organizationTypeOptions[0]}
              onChange={({ detail }) => {
                if (detail.selectedOption.value === '') {
                  searchParams.delete('organization-type')
                } else {
                  searchParams.set('organization-type', detail.selectedOption.value)
                }
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
              options={organizationTypeOptions}
              selectedAriaLabel='Selected'
              empty='No matches found'
            />
          </RadSpaceBetween>
          <RadBox float='right'>
            <RadButton
              onClick={() => {
                searchParams.delete('sector')
                searchParams.delete('tags')
                searchParams.delete('record-type')
                searchParams.delete('resource-type')
                searchParams.delete('organization-type')
                searchParams.delete('project-type')
                searchParams.delete('priority-area')
                searchParams.delete('priority-populations')
                searchParams.delete('dash-funding-program')
                searchParams.delete('page')
                setSearchParams(searchParams)
              }}
            >
              Clear All Filters
            </RadButton>
          </RadBox>
        </RadSpaceBetween>
      }
    >
      <RadButton className='filter' />
    </RadPopover>
  )
}
