import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from './../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBreadcrumbGroup } from '../common/RadBreadcrumbGroup'
import { RadHeader } from '../common/RadHeader'
import { CollaborativeEditContent } from './CollaborativeEditContent'

export function CollaborativeEdit () {
  const { collaborativeId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: collaborative } = useGet(collaborativeId ? `/api/collaborative/${collaborativeId}` : null)

  let items
  if (collaborative == null) {
    items = [
      { text: 'Home', href: '/' },
      { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
      { text: 'New Collaborative' }
    ]
  } else {
    items = [
      { text: 'Home', href: '/' },
      { text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
      { text: collaborative.name, href: `/collaborative/${collaborativeId}` },
      { text: 'Edit' }
    ]
  }

  return (
    <RadAppLayout
      breadcrumbs={
        <RadBreadcrumbGroup
          items={items}
          ariaLabel='Breadcrumbs'
        />
      }
      contentHeader={
        <RadHeader variant='h1'>
          {collaborative != null ? 'Edit Collaborative' : 'New Collaborative'}
        </RadHeader>
      }
      content={
        <CollaborativeEditContent collaborative={collaborative} formValues={formValues} setFormValues={setFormValues} />
      }
    />
  )
}
