import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { useGet } from '../hooks/useGet'
import { AppContext } from './../App'
import { RadIcon } from './RadIcon'
import SideNavigation from '@cloudscape-design/components-themed/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen, pinSidebar, setPinSidebar }) {
  const navigate = useNavigate()
  const { setError } = useContext(AppContext)
  const { data: userInfo } = useGet('/api/user/current')

  const items = [
    { type: 'link', text: 'Home', href: '/' },
    { type: 'link', text: 'DASH Framework', href: '/framework' },
    { type: 'link', text: 'Global Search', href: '/search' },
    {
      type: 'section',
      text: 'Browse',
      items: [
        { type: 'link', text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
        { type: 'link', text: 'Projects', href: '/project' },
        { type: 'link', text: 'Resources', href: '/resource' }
      ]
    },
    { type: 'divider' },
    { type: 'link', text: auth.currentUser?.displayName },
    { type: 'link', text: auth.currentUser?.email },
    { type: 'link', text: 'Sign Out', href: '/signout' }
  ]

  const adminItems = [
    { type: 'link', text: 'Home', href: '/' },
    { type: 'link', text: 'DASH Framework', href: '/framework' },
    { type: 'link', text: 'Global Search', href: '/search' },
    {
      type: 'section',
      text: 'Browse',
      items: [
        { type: 'link', text: 'Organizations & Collaboratives', href: '/organization-collaborative' },
        { type: 'link', text: 'Projects', href: '/project' },
        { type: 'link', text: 'Resources', href: '/resource' }
      ]
    },
    { type: 'divider' },
    {
      type: 'section',
      text: 'Reports',
      items: [
        { type: 'link', text: 'Ask The Librarian', href: '/reports/ask-the-librarian' },
        { type: 'link', text: 'Daily Active Users', href: '/reports/daily-active-users' },
        { type: 'link', text: 'New Records', href: '/reports/new-records' },
        { type: 'link', text: 'New Users', href: '/reports/new-users' },
        { type: 'link', text: 'Record Activity', href: '/reports/record-activity' },
        { type: 'link', text: 'Searches', href: '/reports/search' },
        { type: 'link', text: 'User Activity', href: '/reports/user-activity' },
        { type: 'link', text: 'User Satisfaction', href: '/reports/user-satisfaction' }
      ]
    },
    { type: 'divider' },
    { type: 'link', text: 'Roles', href: '/role' },
    { type: 'link', text: 'Sectors', href: '/sector' },
    { type: 'link', text: 'Services', href: '/service' },
    { type: 'link', text: 'Tags', href: '/tag' },
    { type: 'link', text: 'Priority Populations', href: '/priority-population' },
    { type: 'link', text: 'Types', href: '/type' },
    { type: 'link', text: 'Users', href: '/user' },
    { type: 'divider' },
    { type: 'link', text: <div>Admin Manual <RadIcon name='external' /></div>, href: 'https://docs.google.com/document/d/1mO2NWhys2M5oJWEfg73SpKVgNQ9xU6bapRHQGaO_w-E/edit' },
    { type: 'divider' },
    { type: 'link', text: auth.currentUser?.displayName },
    { type: 'link', text: auth.currentUser?.email },
    { type: 'link', text: 'Sign Out', href: '/signout' },
    { type: 'divider' },
    { type: 'link', text: <div>Powered by Asemio <RadIcon name='external' /></div>, href: 'https://asemio.com' }
  ]

  return (
    <SideNavigation
      activeHref={window.location.pathname}
      onFollow={(event) => {
        event.preventDefault()
        if (event.detail.href.startsWith('http')) {
          window.open(event.detail.href, '_blank')
        } else {
          navigate(event.detail.href)
        }
        setError(null)
      }}
      items={userInfo != null && userInfo.roles.find(x => x.name === 'Public User') != null ? items : adminItems}
    />
  )
}
