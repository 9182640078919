import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { AskTheLibrarianButton } from './AskTheLibrarian'
// import { CollaborativeList } from './collaborative/CollaborativeList'
import { CollaborativeDetail } from './collaborative/CollaborativeDetail'
import { CollaborativeEdit } from './collaborative/CollaborativeEdit'
import { Dashboard } from './Dashboard'
// import { DatasetList } from './dataset/DatasetList'
// import { DatasetDetail } from './dataset/DatasetDetail'
// import { DatasetEdit } from './dataset/DatasetEdit'
// import { OrganizationList } from './organization/OrganizationList'
import { OrganizationDetail } from './organization/OrganizationDetail'
import { OrganizationEdit } from './organization/OrganizationEdit'
import { OrganizationAndCollaborativeList } from './organizationAndCollaborative/OrganizationAndCollaborativeList'
// import { PersonList } from './person/PersonList'
// import { PersonDetail } from './person/PersonDetail'
// import { PersonEdit } from './person/PersonEdit'
import { ProjectList } from './project/ProjectList'
import { ProjectDetail } from './project/ProjectDetail'
import { ProjectEdit } from './project/ProjectEdit'
import { ResourceList } from './resource/ResourceList'
import { ResourceDetail } from './resource/ResourceDetail'
import { ResourceEdit } from './resource/ResourceEdit'
import { RoleList } from './role/RoleList'
import { RoleEdit } from './role/RoleEdit'
import { RoleDetail } from './role/RoleDetail'
import { SearchList } from './search/SearchList'
import { SectorDetail } from './sector/SectorDetail'
import { SectorEdit } from './sector/SectorEdit'
import { SectorList } from './sector/SectorList'
import { ServiceDetail } from './service/ServiceDetail'
import { ServiceEdit } from './service/ServiceEdit'
import { ServiceList } from './service/ServiceList'
import { SignOut } from './SignOut'
// import { SoftwareDetail } from './software/SoftwareDetail'
// import { SoftwareEdit } from './software/SoftwareEdit'
// import { SoftwareList } from './software/SoftwareList'
import { TagList } from './tag/TagList'
import { TagEdit } from './tag/TagEdit'
import { TagDetail } from './tag/TagDetail'
import { TargetPopulationList } from './targetPopulation/TargetPopulationList'
import { TargetPopulationEdit } from './targetPopulation/TargetPopulationEdit'
import { TargetPopulationDetail } from './targetPopulation/TargetPopulationDetail'
import { TopicAreaSearch } from './TopicAreaSearch'
// import { TopicAreaEdit } from './TopicAreaEdit'
import { TypeEdit } from './type/TypeEdit'
import { TypeList } from './type/TypeList'
import { UserList } from './user/UserList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { Searches } from './report/Searches'
import { NewRecords } from './report/NewRecords'
import { NewUsers } from './report/NewUsers'
import { UserActivity } from './report/UserActivity'
import { Chipi } from './chipi/chipi'
import { UserSatisfaction } from './report/UserSatisfaction'
import { AskTheLibrarian } from './report/AskTheLibrarian'
import { RecordActivity } from './report/RecordActivity'
import { DailyActiveUsers } from './report/DailyActiveUsers'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [pinSidebar, setPinSidebar] = useState(window.localStorage.getItem('pinSidebar') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
    window.localStorage.setItem('pinSidebar', pinSidebar)
  }, [navigationOpen, pinSidebar])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal,
        pinSidebar,
        setPinSidebar
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          {/* <Route path='/collaborative' element={<CollaborativeList />} /> */}
          <Route path='/collaborative/:collaborativeId' element={<CollaborativeDetail />} />
          <Route path='/collaborative/create' element={<CollaborativeEdit />} />
          <Route path='/collaborative/:collaborativeId/edit' element={<CollaborativeEdit />} />

          {/* <Route path='/dataset' element={<DatasetList />} />
          <Route path='/dataset/:datasetId' element={<DatasetDetail />} />
          <Route path='/dataset/create' element={<DatasetEdit />} />
          <Route path='/dataset/:datasetId/edit' element={<DatasetEdit />} /> */}

          {/* <Route path='/organization' element={<OrganizationList />} /> */}
          <Route path='/organization/:organizationId' element={<OrganizationDetail />} />
          <Route path='/organization/create' element={<OrganizationEdit />} />
          <Route path='/organization/:organizationId/edit' element={<OrganizationEdit />} />

          <Route path='/organization-collaborative' element={<OrganizationAndCollaborativeList />} />

          {/* <Route path='/person' element={<PersonList />} />
          <Route path='/person/:personId' element={<PersonDetail />} />
          <Route path='/person/create' element={<PersonEdit />} />
          <Route path='/person/:personId/edit' element={<PersonEdit />} /> */}

          <Route path='/project' element={<ProjectList />} />
          <Route path='/project/:projectId' element={<ProjectDetail />} />
          <Route path='/project/create' element={<ProjectEdit />} />
          <Route path='/project/:projectId/edit' element={<ProjectEdit />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/reports/ask-the-librarian' element={<AskTheLibrarian />} />
          <Route path='/reports/daily-active-users' element={<DailyActiveUsers />} />
          <Route path='/reports/new-records' element={<NewRecords />} />
          <Route path='/reports/new-users' element={<NewUsers />} />
          <Route path='/reports/record-activity' element={<RecordActivity />} />
          <Route path='/reports/search' element={<Searches />} />
          <Route path='/reports/user-activity' element={<UserActivity />} />
          <Route path='/reports/user-satisfaction' element={<UserSatisfaction />} />

          <Route path='/resource' element={<ResourceList />} />
          <Route path='/resource/:resourceId' element={<ResourceDetail />} />
          <Route path='/resource/create' element={<ResourceEdit />} />
          <Route path='/resource/:resourceId/edit' element={<ResourceEdit />} />

          <Route path='/search' element={<SearchList />} />

          <Route path='/sector' element={<SectorList />} />
          <Route path='/sector/create' element={<SectorEdit />} />
          <Route path='/sector/:sectorId' element={<SectorDetail />} />
          <Route path='/sector/:sectorId/edit' element={<SectorEdit />} />

          <Route path='/service' element={<ServiceList />} />
          <Route path='/service/create' element={<ServiceEdit />} />
          <Route path='/service/:serviceId' element={<ServiceDetail />} />
          <Route path='/service/:serviceId/edit' element={<ServiceEdit />} />

          <Route path='/signout' element={<SignOut />} />

          {/* <Route path='/software' element={<SoftwareList />} />
          <Route path='/software/create' element={<SoftwareEdit />} />
          <Route path='/software/:softwareId' element={<SoftwareDetail />} />
          <Route path='/software/:softwareId/edit' element={<SoftwareEdit />} /> */}

          <Route path='/tag' element={<TagList />} />
          <Route path='/tag/create' element={<TagEdit />} />
          <Route path='/tag/:tagId' element={<TagDetail />} />
          <Route path='/tag/:tagId/edit' element={<TagEdit />} />

          <Route path='/priority-population' element={<TargetPopulationList />} />
          <Route path='/priority-population/create' element={<TargetPopulationEdit />} />
          <Route path='/priority-population/:targetPopulationId' element={<TargetPopulationDetail />} />
          <Route path='/priority-population/:targetPopulationId/edit' element={<TargetPopulationEdit />} />

          <Route path='/framework' element={<TopicAreaSearch />} />
          {/* <Route path='/framework/edit' element={<TopicAreaEdit />} /> */}

          <Route path='/type' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='/chipi' element={<Chipi />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
        <AskTheLibrarianButton />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
