import React from 'react'
import Spinner from '@cloudscape-design/components-themed/spinner'

// https://cloudscape.design/components/spinner
export function IsLoading ({ loadingCount }) {
  if (loadingCount > 0) {
    return (
      <Spinner size='large' className='global-spinner' />
    )
  }
}
