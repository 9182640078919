import React from 'react'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadLink } from '../common/RadLink'
import { RadBadgeGroup } from '../common/RadBadgeGroup'

export function OrganizationDetailContent ({ organization, setSelectedEntity }) {
  if (organization != null) {
    return (
      <RadColumnLayout columns={3} borders='vertical'>
        <div>
          <RadBox variant='awsui-key-label'>
            Type
          </RadBox>
          <div>{organization.type?.name ?? '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Address
          </RadBox>
          <div>{organization.address?.line1}</div>
          {organization.address?.line2 && <div>{organization.address?.line2}</div>}
          <div>{organization.address?.city} {organization.address?.state} {organization.address?.zip}</div>
          {!organization.addressId && '-'}
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Website
          </RadBox>
          <div>{
            organization.website != null
              ? <RadLink href={organization.website} target='_blank' rel='noopener noreferrer'>{organization.website}</RadLink>
              : '-'
          }
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Contacts
          </RadBox>
          <div>{organization.contacts.length > 0
            ? organization.contacts.map((x) => <div key={x.id}>{x.name}</div>)
            : '-'}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Sectors
          </RadBox>
          <div>
            <RadBadgeGroup
              parentKey={organization.id}
              items={organization.sectors ?? []}
              mapFunc={x => ({ key: x.id, name: x.sector?.name })}
            />
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Services
          </RadBox>
          <div>{
            organization.services?.length > 0
              ? organization.services.map((x) => <div key={x.id}>{x.service?.name}</div>)
              : '-'
          }
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Priority Areas
          </RadBox>
          <div>{organization.targetAreas?.length > 0
            ? organization.targetAreas.map((x) => <div key={x.id}>{x.targetArea?.name}</div>)
            : '-'}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Priority Populations
          </RadBox>
          <div>{organization.targetPopulations?.length > 0 ? organization.targetPopulations.map((x) => <div key={x.id}>{x.targetPopulation?.name}</div>) : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Tags
          </RadBox>
          <div>
            <RadBadgeGroup
              parentKey={organization.id}
              items={organization.tags}
              mapFunc={x => ({ key: x.id, name: x.tag?.name })}
            />
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Projects
          </RadBox>
          <div>{organization.projects?.length > 0
            ? organization.projects.map((x) =>
              <div key={x.id}>
                {setSelectedEntity == null && <RadLink href={`/project/${x.project.id}`}>{x.project?.name}</RadLink>}
                {setSelectedEntity != null &&
                  <RadButton
                    onClick={() => setSelectedEntity({ type: 'project', id: x.project.id })}
                    className='font-weight-400'
                    variant='inline-link'
                  >
                    {x.project?.name}
                  </RadButton>}
              </div>
            )
            : '-'}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Collaboratives
          </RadBox>
          <div>{organization.collaboratives?.length > 0
            ? organization.collaboratives.map((x) =>
              <div key={x.id}>
                {setSelectedEntity == null && <RadLink href={`/collaborative/${x.collaborative.id}`}>{x.collaborative.name}</RadLink>}
                {setSelectedEntity != null &&
                  <RadButton
                    onClick={() => setSelectedEntity({ type: 'collaborative', id: x.collaborative.id })}
                    className='font-weight-400'
                    variant='inline-link'
                  >
                    {x.collaborative.name}
                  </RadButton>}
              </div>)
            : '-'}
          </div>
        </div>
      </RadColumnLayout>
    )
  }
}
