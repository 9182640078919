import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { ResourceEditContent } from './ResourceEditContent'

export function ResourceEdit () {
  const { resourceId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: resource } = useGet(resourceId ? `/api/resource/${resourceId}` : null, true)

  return (
    <RadAppLayout
      name={resource?.title}
      contentHeader={
        <RadHeader variant='h1'>
          {resource != null ? 'Edit Resource' : 'New Resource'}
        </RadHeader>
        }
      content={
        <ResourceEditContent resource={resource} formValues={formValues} setFormValues={setFormValues} />
      }
    />
  )
}
