
    import './styles.scoped.css';
    export default {
  "badge": "awsui_badge_1yjyg_i8whe_141",
  "badge-color-grey": "awsui_badge-color-grey_1yjyg_i8whe_187",
  "badge-color-green": "awsui_badge-color-green_1yjyg_i8whe_190",
  "badge-color-blue": "awsui_badge-color-blue_1yjyg_i8whe_193",
  "badge-color-red": "awsui_badge-color-red_1yjyg_i8whe_196",
  "badge-color-severity-critical": "awsui_badge-color-severity-critical_1yjyg_i8whe_199",
  "badge-color-severity-high": "awsui_badge-color-severity-high_1yjyg_i8whe_203",
  "badge-color-severity-medium": "awsui_badge-color-severity-medium_1yjyg_i8whe_207",
  "badge-color-severity-low": "awsui_badge-color-severity-low_1yjyg_i8whe_211",
  "badge-color-severity-neutral": "awsui_badge-color-severity-neutral_1yjyg_i8whe_215"
};
  