import React from 'react'
import { RadBadgeGroup } from '../common/RadBadgeGroup'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadExternalLink } from '../common/RadExternalLink'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate } from '../common/utilities'
import { ResourceFeedback } from './ResourceFeedback'
import { ReportBrokenLinkButton } from './ReportBrokenLinkButton'

export function ResourceDetailContent ({ resource, setSelectedEntity }) {
  return (
    <RadSpaceBetween size='l'>
      <RadSpaceBetween size='l'>
        {
          resource.sourceId === 0 &&
            <div>
              <RadBox variant='awsui-key-label'>
                File
              </RadBox>
              <RadSpaceBetween>
                {resource.file != null && <RadExternalLink href={resource.file} />}
                {resource.fileSize != null && <div>{resource.fileSize}</div>}
              </RadSpaceBetween>
            </div>
        }
        {
          resource.sourceId === 1 &&
            <div>
              <RadBox variant='awsui-key-label'>
                Link
              </RadBox>
              <RadLink external externalIconAriaLabel='Opens in a new tab' href={resource.link} target='_blank'>{resource.link}</RadLink>
              <ReportBrokenLinkButton resource={resource} />
            </div>
        }
        <RadColumnLayout columns={4} borders='vertical'>
          <div>
            <RadBox variant='awsui-key-label'>
              Type
            </RadBox>
            <RadBox padding={{ bottom: 'xxs' }}>
              {resource.type?.name ?? '-'}
            </RadBox>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Publisher
            </RadBox>
            <div>{resource.publisher ?? '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Publication Date
            </RadBox>
            <div>{resource.publicationDate != null ? formatDate(resource.publicationDate) : '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Author
            </RadBox>
            <div>{resource.author ?? '-'}</div>
          </div>
          <div>
            <RadBox variant='awsui-key-label'>
              Legal Bibliography
            </RadBox>
            {resource.legalBibliography == null && <div>-</div>}
            {resource.legalBibliography?.legalScore != null && <div>Legal Score: {resource.legalBibliography.legalScore.name}</div>}
            {resource.legalBibliography?.dataScore != null && <div>Data Score: {resource.legalBibliography.dataScore.name}</div>}
            {resource.legalBibliography?.valueScore != null && <div>Value Score: {resource.legalBibliography.valueScore.name}</div>}
          </div>
        </RadColumnLayout>
        <div>
          <RadBox variant='awsui-key-label'>
            Projects
          </RadBox>
          <div>{resource.projects?.length > 0
            ? resource.projects.map((x) =>
              <div key={'project-' + x.id}>
                {setSelectedEntity == null && <RadLink href={`/project/${x.project.id}`}>{x.project?.name}</RadLink>}
                {setSelectedEntity != null &&
                  <RadButton
                    onClick={() => setSelectedEntity({ type: 'project', id: x.project.id })}
                    className='font-weight-400'
                    variant='inline-link'
                  >
                    {x.project?.name}
                  </RadButton>}
              </div>
            )
            : '-'}
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Sectors
          </RadBox>
          <div>
            <RadBadgeGroup
              parentKey={resource.id}
              items={resource.sectors ?? []}
              mapFunc={x => ({ key: x.id, name: x.sector?.name })}
            />
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Tags
          </RadBox>
          <div>
            <RadBadgeGroup
              parentKey={resource.id}
              items={resource.tags ?? []}
              mapFunc={x => ({ key: x.id, name: x.tag?.name })}
            />
          </div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Priority Populations
          </RadBox>
          <div>{resource.targetPopulations?.length > 0 ? resource.targetPopulations.map((x) => <div key={x.id}>{x.targetPopulation.name}</div>) : '-'}</div>
        </div>
        <div>
          <RadBox variant='awsui-key-label'>
            Project Types
          </RadBox>
          <div>{resource.useCases?.length > 0 ? resource.useCases.map((x) => <div key={x.id}>{x.useCase?.name}</div>) : '-'}</div>
        </div>
        {/* <div>
          <RadBox variant='awsui-key-label'>
            Include in Framework?
          </RadBox>
          <div>{resource.topics?.length > 0 ? resource.topics.map((x) => <div key={x.id}>{x.topic?.name}</div>) : '-'}</div>
        </div> */}
        <div>
          <RadBox variant='awsui-key-label'>
            Visibility<RadBox color='text-body-secondary' display='inline'>{resource.isPrivate === true ? ' (Private)' : ''}</RadBox>
          </RadBox>
          {resource.shares?.map((x) => <div key={x.id}><RadLink href={`/user/${x.user.id}`}>{x.user.name}</RadLink> - {x.allowEdit === false ? 'View only' : 'View and edit'}</div>)}
          {resource.isPrivate === false ? 'Public' : ''}
        </div>
        <ResourceFeedback resource={resource} />
      </RadSpaceBetween>
      {
        resource.sourceId === 2 &&
          <RadContainer
            header={
              <RadHeader variant='h2'>
                Text
              </RadHeader>
            }
          >
            {resource.text}
          </RadContainer>
      }
    </RadSpaceBetween>
  )
}
