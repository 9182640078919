import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useConfirm } from '../hooks/useConfirm'
import { useDelete } from '../hooks/useDelete'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function TargetPopulationDetail () {
  const navigate = useNavigate()
  const { targetPopulationId } = useParams()
  const { data: targetPopulation } = useGet(`/api/target-population/${targetPopulationId}`)
  const confirmDelete = useConfirm('Delete Target Population?', 'Delete target population permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/target-population/${targetPopulationId}`, () => navigate('/priority-population'))

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (targetPopulation != null) {
    return (
      <RadAppLayout
        name={targetPopulation.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={targetPopulation.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {targetPopulation.name}
          </RadHeader>
        }
      />
    )
  }
}
