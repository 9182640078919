
    import './styles.scoped.css';
    export default {
  "button-trigger": "awsui_button-trigger_18eso_4gd9x_157",
  "arrow": "awsui_arrow_18eso_4gd9x_157",
  "in-filtering-token-root": "awsui_in-filtering-token-root_18eso_4gd9x_220",
  "in-filtering-token-nested": "awsui_in-filtering-token-nested_18eso_4gd9x_220",
  "has-caret": "awsui_has-caret_18eso_4gd9x_254",
  "placeholder": "awsui_placeholder_18eso_4gd9x_257",
  "pressed": "awsui_pressed_18eso_4gd9x_270",
  "disabled": "awsui_disabled_18eso_4gd9x_273",
  "in-filtering-token": "awsui_in-filtering-token_18eso_4gd9x_220",
  "readonly": "awsui_readonly_18eso_4gd9x_289",
  "invalid": "awsui_invalid_18eso_4gd9x_311",
  "warning": "awsui_warning_18eso_4gd9x_320",
  "inline-tokens": "awsui_inline-tokens_18eso_4gd9x_329"
};
  