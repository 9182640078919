import moment from 'moment'

export const removeEmptyStrings = (obj) => {
  const isArray = Array.isArray(obj)
  for (const k of Object.keys(obj)) {
    if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'string' && obj[k].trim() === '') {
      obj[k] = null
    } else if (obj[k] !== null && obj[k] !== undefined && typeof obj[k] === 'object') {
      removeEmptyStrings(obj[k])
    }
    if (isArray && obj.length === k) {
      removeEmptyStrings(obj)
    }
  }
  return obj
}

export function formatDate (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY')
}

export function formatDateTime (inputString) {
  if (inputString == null) {
    return null
  }
  return moment(inputString).format('MM/DD/YYYY h:mm A')
}

export function getOrdinal (number) {
  switch (number) {
    case undefined:
      return ''
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export function getShortText (text) {
  if (text == null) return text
  let results = ''
  const words = text.split(' ')
  while (results.length < 240 && words.length > 0) {
    results += words.splice(0, 1) + ' '
  }
  if (results.length < text.length) results += ' ...'
  return results
}

export function isNullOrWhitespace (value) {
  return !value || !value.trim()
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
  reader.readAsDataURL(file)
})

export function toTitleCase (str) {
  return str
    .split('_')
    .filter(x => x.length > 0)
    .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
    .join(' ')
}

export function jsonToFormData (json) {
  const formData = new FormData()
  for (const file of json.files ?? []) {
    formData.append('file', file, file.name)
  }
  const clone = Object.assign({}, json)
  formData.append('values', JSON.stringify(removeEmptyStrings(clone)))
  return formData
}
