import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useConfirm } from '../hooks/useConfirm'
import { useDelete } from '../hooks/useDelete'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function ServiceDetail () {
  const navigate = useNavigate()
  const { serviceId } = useParams()
  const { data: service } = useGet(`/api/service/${serviceId}`)
  const confirmDelete = useConfirm('Delete service?', 'Delete service permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/service/${serviceId}`, () => navigate('/service'))

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (service != null) {
    return (
      <RadAppLayout
        name={service.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={service.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {service.name}
          </RadHeader>
        }
      />
    )
  }
}
